import React, { Component } from 'react';
import { Container, Card, Spinner, Row, Col, Button, Badge, Form, Image, } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import axios from 'axios';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, Link, Navigate } from 'react-router-dom';
import { faArrowRight, faBars, faCalendarAlt, faClock, faExternalLink, faFileAlt, aLock, faStickyNote, faLock, } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment/moment';
import { withParams } from '../../helpers/params_helper';
import { ReactSortable } from 'react-sortablejs';
import './exams.css';
import './ck-editor.css';
import examTitleIcon from '../../assets/images/exam.png';
import submitExam from '../../assets/images/submit-exam.png';
import timeLeft from '../../assets/images/wall-clock-red.png';
import examNote from '../../assets/images/info-slim-yellow.png';
import qlOpen from '../../assets/images/question-list-open.png';
import qlClose from '../../assets/images/question-list-close.png';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

/**
 * Attempt Exam Component
 */
class AttemptExam extends Component {
    // State
    state = {
        examDetails: null,
        examLocked: null,
        ExamAnswers: [],
        shouldRedirect: false,
        working: false,
        qListPanel: false,
        QsNums: [],
    };

    /**
     * Constructor
     */
    constructor() {
        super();
        this.qList = React.createRef();
        this.qListNums = React.createRef();
    }

    /**
     * Component Did Mount
     */
    componentDidMount() {
        this.getExamDetails();
    }

    /**
     * Get Exam Details
     */
    getExamDetails = async () => {
        const { authData } = this.props;
        const { auth_key } = authData.loginData;
        const { center_id, center_timezone } = authData.centerData;
        const { selectedChild, params } = this.props;
        const { exams_id, exam_retake_id } = params;

        const fData = new FormData();

        fData.append('center_id', center_id);
        fData.append('center_timezone', center_timezone);
        fData.append('exams_id', exams_id);
        fData.append('class_id', selectedChild.class_id);
        fData.append('child_id', selectedChild.child_id);
        fData.append('parent_id', selectedChild.parent_id);
        fData.append(
            'exam_retake_id',
            exam_retake_id == 0 ? null : exam_retake_id
        );

        try {
            const req = await axios.post(
                apiURL + 'exam-attempt/get-exam-details',
                fData,
                {
                    params: {
                        auth_key: auth_key,
                    },
                }
            );

            if (req.data.type) {
                const ExamAnswers = [];
                const QsNums = [];

                const RemoteExamAnswers = req.data.data.ExamAnswers;

                // Create Answers
                req.data.data.Examsquest.map((item, index) => {
                    if (item.examsquesttype.eqtype_id == 9) {
                        return false;
                    }

                    let exam_ans_id = 0;
                    let attachment = null;
                    let foundAnswer;

                    if (RemoteExamAnswers.length > 0) {
                        foundAnswer = RemoteExamAnswers.find(
                            (foundAnswerItem) => {
                                return (
                                    foundAnswerItem.examsquest_id ==
                                    item.examsquest_id
                                );
                            }
                        );

                        exam_ans_id = foundAnswer.exam_ans_id;
                        attachment = foundAnswer.attachment;
                    }

                    ExamAnswers.push({
                        exam_ans_id,
                        center_id: center_id,
                        child_id: selectedChild.child_id,
                        parent_id: selectedChild.parent_id,
                        class_id: item.class_id,
                        examsquest_id: item.examsquest_id,
                        exams_id: item.exams_id,
                        answer: null,
                        attachment: attachment,
                        examsquest_duration: 0,
                        is_time_over: 0,
                        answer_grade: 0,
                        is_correct: '',
                        checked_by_employee: null,
                        checked_by_datetime: null,
                        confirmed_answer_grade: 0,
                        examAns: [],
                        eqtype_id: item.examsquesttype.eqtype_id,
                    });

                    console.log('foundAnswer', foundAnswer);
                    QsNums.push({
                        qid: item.examsquest_id,
                        status: (foundAnswer) ? foundAnswer.examAns.length == 0 ? 'unattempted' : 'attempted' : 'unattempted',
                    });
                });

                this.setState({
                    examDetails: req.data.data,
                    ExamAnswers,
                    examLocked: null,
                    QsNums,
                });
            } else {
                this.setState({
                    examLocked: {
                        message: req.data.message,
                        messageTitle: req.data.messageTitle,
                    },
                });
            }
        } catch (err) {
            toast.error('Something went wrong while fetching exam data!');
            console.log(err);
        }
    };

    /**
     * Render Exam Questions
     * @returns {HTMLElement}
     */
    renderExamQs = () => {
        const { examDetails } = this.state;
        const { Examsquest, ExamAnswers } = examDetails;

        const questions = Examsquest.map((item, index) => {
            // Exclude if the question is Audio Recording
            if (item.examsquesttype.eqtype_id == 9) {
                return false;
            }

            return (
                <Card
                    key={index}
                    className='mb-4 border-0'
                    id={'q-' + item.examsquest_id}>
                    <Card.Body>
                        <Row className='q-card-header'>
                            <Col className='q-type ps-0 pb-2'>
                                {index + 1}. {item.examsquesttype.eqtype_name}
                            </Col>
                            <Col className='d-flex justify-content-end pe-0 pb-2'>
                                <button
                                    onClick={() =>
                                        this.updateQNumStatus(
                                            index,
                                            'attemptlater'
                                        )
                                    }
                                    className='pe-2 ps-2 me-2 mark-attempt-later-btn'>
                                    {t('Mark as attempt later')}
                                </button>
                                <Badge
                                    bg='primary'
                                    className='me-2 d-flex align-items-center'>
                                    {t('Marks:') + ' ' + item.examsquest_grade}
                                </Badge>
                                {/* <Badge
                                    bg='warning'
                                    text='dark'
                                    className='me-2 d-flex align-items-center'>
                                    {t('Time:') +
                                        ' ' +
                                        item.examsquest_duration}
                                </Badge> */}
                                <Badge
                                    bg='success'
                                    className='d-flex align-items-center'>
                                    {t('Mandatory:') +
                                        ' ' +
                                        (item.is_mandatory == 1
                                            ? t('Yes')
                                            : t('No'))}
                                </Badge>
                            </Col>
                        </Row>
                        <div
                            className='ck-content'
                            dangerouslySetInnerHTML={{
                                __html: item.examsquest_desc1.replaceAll(
                                    '<input/>',
                                    '<input class="fitb fitb-' +
                                    item.examsquest_id +
                                    '" />'
                                ),
                            }}
                        />
                        {this.examQuestionOpts(
                            item.examsquesttype.eqtype_id,
                            item.examsquestoptions,
                            item.examsquest_id,
                            index,
                            ExamAnswers.length > 0
                                ? ExamAnswers.find((found_item) => {
                                    return (
                                        found_item.examsquest_id ==
                                        item.examsquest_id
                                    );
                                })
                                : null
                        )}
                    </Card.Body>
                </Card>
            );
        });

        return questions;
    };

    /**
     * Exam Question Options
     * @param {number} eqtype_id
     * @param {array} opts
     * @param {number} qid
     * @param {number} index
     * @returns
     */
    examQuestionOpts = (eqtype_id, opts, qid, index, answers) => {
        // MCQs
        if (eqtype_id == 1) {
            let qindex = index;
            let answer_exists = false;
            const qopts = opts.map((item, index) => {
                if (answers !== null) {
                    const find_answer = answers.examAns.findIndex(
                        (found_item) => {
                            return (
                                found_item.examsquestoptions_id_selected ==
                                item.examsquestoptions_id
                            );
                        }
                    );
                    answer_exists = find_answer !== -1 ? true : false;
                }
                return (
                    <Form.Check
                        key={index}
                        inline
                        type='radio'
                        id={`default-${item.examsquestoptions_id}`}
                        name={`opt-${item.examsquest_id}`}
                        label={item.option_text}
                        value={item.option_text}
                        data-id={item.examsquestoptions_id}
                        defaultChecked={answer_exists}
                        onClick={() =>
                            this.updateQNumStatus(qindex, 'attempted')
                        }
                    />
                );
            });

            return (
                <div key={`inline-radio`} className='mb-3'>
                    {qopts}
                </div>
            );
        }
        // MRQs
        if (eqtype_id == 2) {
            let qindex = index;
            let answer_exists = false;
            const qopts = opts.map((item, index) => {
                if (answers !== null) {
                    const find_answer = answers.examAns.findIndex(
                        (found_item) => {
                            return (
                                found_item.examsquestoptions_id_selected ==
                                item.examsquestoptions_id
                            );
                        }
                    );
                    answer_exists = find_answer !== -1 ? true : false;
                }

                return (
                    <Form.Check
                        key={index}
                        inline
                        type='checkbox'
                        id={`opt-${item.examsquestoptions_id}`}
                        name={`opt-${item.examsquestoptions_id}`}
                        label={item.option_text}
                        value={item.option_text}
                        data-id={item.examsquestoptions_id}
                        defaultChecked={answer_exists}
                        onClick={() =>
                            this.updateQNumStatus(qindex, 'attempted')
                        }
                    />
                );
            });

            return (
                <div key={`inline-checkbox`} className='mb-3'>
                    {qopts}
                </div>
            );
        }
        // True False
        if (eqtype_id == 3) {
            let qindex = index;
            let answer_exists = false;
            const qopts = opts.map((item, index) => {
                if (answers !== null) {
                    const find_answer = answers.examAns.findIndex(
                        (found_item) => {
                            return (
                                found_item.examsquestoptions_id_selected ==
                                item.examsquestoptions_id
                            );
                        }
                    );
                    answer_exists = find_answer !== -1 ? true : false;
                }
                return (
                    <Form.Check
                        key={index}
                        inline
                        type='radio'
                        id={`default-${item.examsquestoptions_id}`}
                        name={`opt-${item.examsquest_id}`}
                        label={item.option_text}
                        value={item.option_text}
                        data-id={item.examsquestoptions_id}
                        defaultChecked={answer_exists}
                        onClick={() =>
                            this.updateQNumStatus(qindex, 'attempted')
                        }
                    />
                );
            });

            return (
                <div key={`inline-radio`} className='mb-3'>
                    {qopts}
                </div>
            );
        }

        // Brief Description
        if (eqtype_id == 4) {
            return (
                <Form.Control
                    as='textarea'
                    placeholder={t('Answer here')}
                    style={{ height: '100px' }}
                    id={`brief-desc-${qid}`}
                    defaultValue={answers ? answers.answer : ''}
                    onChange={() => this.updateQNumStatus(index, 'attempted')}
                />
            );
        }

        // Detailed Description
        if (eqtype_id == 5) {
            return (
                <Form.Control
                    as='textarea'
                    placeholder={t('Answer here')}
                    style={{ height: '100px' }}
                    id={`brief-desc-${qid}`}
                    defaultValue={answers ? answers.answer : ''}
                    onChange={() => this.updateQNumStatus(index, 'attempted')}
                />
            );
        }

        // Matching Words
        if (eqtype_id == 6) {
            let answer_options = null;

            if (answers !== null && answers.examAns.length > 0) {
                answer_options = answers.examAns;
            }

            return (
                <Row style={{ maxWidth: '40%' }}>
                    <Col>
                        {opts.map((item, index) => (
                            <div key={index} className='word-match-to'>
                                {item.option_text}
                            </div>
                        ))}
                    </Col>
                    <Col md={1}>
                        {opts.map((item, index) => (
                            <div
                                key={index}
                                style={{ height: '48px', marginBottom: '5px' }}
                                className='d-flex justify-content-center align-items-center'>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        ))}
                    </Col>
                    <Col>
                        <MatchingWords
                            opts={opts}
                            answerOptions={answer_options}
                            index={index}
                            updateMatchingWords={this.updateMatchingWords}
                        />
                    </Col>
                </Row>
            );
        }

        // File Upload
        if (eqtype_id == 7) {
            const fileID = 'file-' + qid;
            return (
                <div style={{ maxWidth: '400px', textAlign: 'center' }}>
                    {answers && (
                        <img
                            src={`${portalURL + answers.center_id
                                }/exam_answers/${answers.attachment}`}
                            alt=''
                            className='mb-3'
                            width='150'
                            height='auto'
                        />
                    )}
                    <Form.Control
                        type='file'
                        id={fileID}
                        onChange={() => {
                            this.uploadImage(qid, index);
                            this.updateQNumStatus(index, 'attempted');
                        }}
                    />
                </div>
            );
        }

        // Fill if the blank
        if (eqtype_id == 8) {
            var elements = document.getElementsByClassName('fitb-' + qid);

            for (var i = 0, len = elements.length; i < len; i++) {
                elements[i].value =
                    answers && answers.examAns.length > 0
                        ? answers.examAns[i].examsquestoption2
                        : '';
            }
        }
    };

    /**
     * Save Exam Attempt
     */
    saveExamAttempt = async () => {
        this.setState({ working: true });
        const { examDetails, ExamAnswers } = this.state;
        const { Examsquest } = examDetails;
        const { authData } = this.props;
        const { auth_key } = authData.loginData;
        const { center_id, center_timezone } = authData.centerData;
        const { selectedChild, params } = this.props;
        const { exams_id, exam_retake_id } = params;
        const { class_id, child_id, parent_id } = selectedChild;

        let NewExamAnswers = ExamAnswers;

        Examsquest.map((item, index) => {
            //  MCQs | True / False
            if (
                item.examsquesttype.eqtype_id == 1 ||
                item.examsquesttype.eqtype_id == 3
            ) {
                if (
                    document.querySelector(
                        `input[name="opt-${item.examsquest_id}"]:checked`
                    )
                ) {
                    const id = document
                        .querySelector(
                            `input[name="opt-${item.examsquest_id}"]:checked`
                        )
                        .getAttribute('data-id');
                    const answer = document.querySelector(
                        `input[name="opt-${item.examsquest_id}"]:checked`
                    ).value;

                    NewExamAnswers[index].examAns = [
                        {
                            ans_detail_id: 0,
                            exam_ans_id: 0,
                            examsquestoptions_id_selected: id,
                            examsquestoption: answer,
                            examsquestoptions_id_selected2: null,
                            examsquestoption2: null,
                        },
                    ];
                }
            }

            // MRQ
            if (item.examsquesttype.eqtype_id == 2) {
                const mrq_answers = [];

                item.examsquestoptions.map((opt_item, opt_index) => {
                    const opt_id = opt_item.examsquestoptions_id;
                    if (document.querySelector(`#opt-${opt_id}:checked`)) {
                        const checkbox = document.querySelector(
                            `#opt-${opt_id}:checked`
                        );
                        mrq_answers.push({
                            ans_detail_id: 0,
                            exam_ans_id: 0,
                            examsquestoptions_id_selected:
                                checkbox.getAttribute('data-id'),
                            examsquestoption: checkbox.value,
                            examsquestoptions_id_selected2: null,
                            examsquestoption2: null,
                        });
                    }
                });

                NewExamAnswers[index].examAns = mrq_answers;
            }

            //  Brief Description | Detailed Description
            if (
                item.examsquesttype.eqtype_id == 4 ||
                item.examsquesttype.eqtype_id == 5
            ) {
                const answer = document.getElementById(
                    `brief-desc-${item.examsquest_id}`
                ).value;
                NewExamAnswers[index].answer = answer;
            }

            //  Fill In The Blank
            if (item.examsquesttype.eqtype_id == 8) {
                const fitb_answers = [];

                var elements = document.getElementsByClassName(
                    'fitb-' + item.examsquest_id
                );

                for (var i = 0, len = elements.length; i < len; i++) {
                    fitb_answers.push({
                        ans_detail_id: 0,
                        exam_ans_id: 0,
                        examsquestoptions_id_selected:
                            item.examsquestoptions[i].examsquestoptions_id,
                        examsquestoption: null,
                        examsquestoptions_id_selected2:
                            item.examsquestoptions[i].examsquestoptions_id,
                        examsquestoption2: elements[i].value,
                    });
                }

                NewExamAnswers[index].examAns = fitb_answers;
            }
        });

        // console.log('examDetails', examDetails);
        // console.log('Examsquest', Examsquest);
        // console.log('NewExamAnswers', NewExamAnswers);

        // console.log('exams_id', exams_id);
        // console.log('class_id', class_id);
        // console.log('child_id', child_id);
        // console.log('parent_id', parent_id);

        const fData = new FormData();
        fData.append('exams_id', exams_id);
        fData.append('class_id', class_id);
        fData.append('child_id', child_id);
        fData.append('parent_id', parent_id);
        fData.append('center_id', center_id);
        fData.append('center_timezone', center_timezone);
        fData.append('newExamAnswers', JSON.stringify(NewExamAnswers));

        try {
            const req = await axios.post(
                apiURL + 'exam-attempt/save-exam-attempt',
                fData,
                {
                    params: {
                        auth_key: auth_key,
                    },
                }
            );

            if (req.status === 200) {
                if (req.data.type) {
                    toast.success('Exam saved successfully!');
                    this.setState({
                        shouldRedirect: true,
                        working: false,
                    });
                } else {
                    toast.error(req.data.messageTitle + ' ' + req.data.message);
                    this.setState({
                        working: false,
                    });
                }
            }
        } catch (err) {
            toast.error('Something went wrong while saving exam!');
            this.setState({ working: false });
            console.log(err);
        }
    };

    /**
     * Update Matching Words
     * @param {number} index
     * @param {array} data
     */
    updateMatchingWords = (index, data) => {
        const { authData } = this.props;
        const { center_id, center_timezone } = authData.centerData;
        const { examDetails, ExamAnswers } = this.state;
        const { Examsquest } = examDetails;
        const { examsquestoptions } = Examsquest[index];
        const matchingWords = [];

        for (var i = 0; i < examsquestoptions.length; ++i) {
            matchingWords.push({
                ans_detail_id: 0,
                exam_ans_id: 0,
                center_id: center_id,
                examsquestoptions_id_selected:
                    examsquestoptions[i].examsquestoptions_id,
                examsquestoption: examsquestoptions[i].option_text,
                examsquestoptions_id_selected2: data[i].id,
                examsquestoption2: data[i].name,
            });
        }

        ExamAnswers[index].examAns = matchingWords;

        this.setState({
            ExamAnswers,
        });
    };

    /**
     * Upload Image
     * @param {number} qid
     * @param {number} index
     */
    uploadImage = (qid, index) => {
        const $this = this;
        const { ExamAnswers } = this.state;

        console.log(document.querySelector('#file-' + qid).files[0]);

        const file = document.querySelector('#file-' + qid).files[0];

        if (file) {
            var FR = new FileReader();
            FR.readAsDataURL(file);
            FR.addEventListener('load', function (e) {
                ExamAnswers[index].attachment = e.target.result.split(',')[1];

                $this.setState({ ExamAnswers });
            });
        }
    };

    /**
     * Hide / Show Qs Numbers
     */
    hideShowQList = () => {
        const { qListPanel } = this.state;
        this.qList.current.style.marginLeft = qListPanel ? '300px' : '0';
        this.qListNums.current.style.left = qListPanel ? '10px' : '-400px';
        this.setState({ qListPanel: !qListPanel });
    };

    /**
     * Render Questions Number
     * @returns {HTMLElement}
     */
    renderQsNums = () => {
        const { QsNums } = this.state;

        const qNums = QsNums.map((item, index) => {
            return (
                <button
                    key={index}
                    className={item.status}
                    onClick={() => this.scrollToQ(item.qid)}>
                    {index + 1}
                </button>
            );
        });

        return qNums;
    };

    /**
     * Update Question Number Status
     * @param {number} index
     * @param {string} status
     */
    updateQNumStatus = (index, status) => {
        const { QsNums } = this.state;

        QsNums[index].status = status;

        this.setState({ QsNums });
    };

    /**
     * Scroll To The Question
     * @param {string} qid
     */
    scrollToQ = (qid) => {
        document.getElementById('q-' + qid).scrollIntoView({
            behavior: 'smooth',
        });
    };

    /**
     * Render Component
     * @returns {HTMLElement}
     */
    render() {
        // Language Direction
        const { lang_orientation: dir } = this.props.defaultLanguage;
        let is_homework = (this.props.params.is_homework == 'true') ? true : false

        // State
        const { examDetails, examLocked, shouldRedirect, working, qListPanel } =
            this.state;

        if (shouldRedirect) {
            return <Navigate replace to={is_homework ? '/homework' : '/exams'} />;
        }

        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <div id='page-content' style={{ paddingLeft: 0 }}>
                        <header
                            id='iedu-header'
                            className='pt-3'
                            style={{ height: 100 }}>
                            <Row>
                                <Col
                                    md={4}
                                    className='d-flex align-items-center'>
                                    <h4 className='exam-title mb-0'>
                                        {examLocked !== null &&
                                            t(examLocked.messageTitle)}
                                        {examDetails !== null && (
                                            <>
                                                <img src={examTitleIcon} alt='' width='35' style={{ marginRight: 7 }} />
                                                {examDetails.Exams.exams_name}
                                            </>
                                        )}
                                    </h4>
                                </Col>
                                <Col md={8} className='d-flex justify-content-end me-0'>
                                    {examDetails !== null && (
                                        <>
                                            {examDetails.Exams.exams_notes &&
                                                <h6
                                                    className='me-3 mb-0 d-flex align-items-center'
                                                    style={{ width: '300px', backgroundColor: '#fff', borderRadius: 35, padding: '0 20px', }}>
                                                    <span className='me-2'>
                                                        <img
                                                            src={examNote}
                                                            alt=''
                                                            width='40'
                                                            style={{
                                                                marginRight: 7,
                                                            }}
                                                        />
                                                    </span>
                                                    <span>
                                                        {
                                                            examDetails.Exams
                                                                .exams_notes
                                                        }
                                                    </span>
                                                </h6>
                                            }
                                            <ExamTimeLeft
                                                dir={dir}
                                                examDetails={examDetails}
                                            />
                                            <button
                                                style={{
                                                    opacity: working ? 0.5 : 1,
                                                    position: 'relative'
                                                }}
                                                className='submit-exam-btn'
                                                disabled={working}
                                                onClick={() =>
                                                    this.saveExamAttempt()
                                                }>
                                                {working &&
                                                    <div style={{
                                                        width: 30,
                                                        height: 30,
                                                        borderRadius: 30 / 2,
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        background: '#8BD399',
                                                        transform: 'translate(-50%, -50%)',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Spinner
                                                            size="sm"
                                                            animation='grow'
                                                            variant='light'
                                                        />
                                                    </div>
                                                }
                                                <img
                                                    src={submitExam}
                                                    alt=''
                                                    width='70'
                                                />
                                            </button>
                                        </>
                                    )}
                                    {examLocked !== null && (
                                        <button
                                            onClick={() => window.history.back()}
                                            style={{ borderRadius: 30, fontWeight: 700, textTransform: 'uppercase' }}
                                            className='btn btn-sm btn-primary px-3'>
                                            {t('Go Back')}
                                        </button>
                                    )}
                                </Col>
                            </Row>
                        </header>
                        {examDetails !== null ? (
                            <>
                                <button
                                    className='ql-btn'
                                    onClick={() => this.hideShowQList()}>
                                    <img
                                        src={qListPanel ? qlOpen : qlClose}
                                        alt=''
                                        width='35'
                                    />
                                </button>
                                <div
                                    className='q-list-nums'
                                    ref={this.qListNums}>
                                    <h6
                                        className='text-center pb-2 mb-3'
                                        style={{
                                            borderBottom: '#9b9b9b 1px solid',
                                        }}>
                                        {t('Question List')}
                                    </h6>
                                    <div className='q-list-nums-grid'>
                                        {this.renderQsNums()}
                                    </div>
                                </div>
                                <div id='exam-qs' ref={this.qList}>
                                    <div className='grey-section'>
                                        {this.renderExamQs()}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div
                                style={{
                                    height: '120px',
                                    flexDirection: 'column',
                                    width: '400px',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                }}
                                className='d-flex justify-content-center align-items-center text-center'>
                                {examLocked == null ? (
                                    <>
                                        <span className='mb-1'>
                                            <Spinner
                                                animation='grow'
                                                variant='dark'
                                            />
                                        </span>
                                        {is_homework ? t('Fetching homework...') : t('Fetching exam...')}
                                    </>
                                ) : (
                                    <>
                                        <span
                                            className='mb-1'
                                            style={{ fontSize: '30px' }}>
                                            <FontAwesomeIcon icon={faLock} />
                                        </span>
                                        {t(examLocked.message)}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedChild: state.selectedChild.data,
    languages: state.language.languages,
    defaultLanguage: state.language.defaultLanguage,
});
export default connect(mapStateToProps, null)(withParams(AttemptExam));

/***********************
 * EXTERNAL COMPONENTS *
 ***********************/

/**
 * Matching Words Component
 */
class MatchingWords extends Component {
    // State
    state = {
        list: null,
    };

    /**
     * Component Did Mount
     */
    componentDidMount() {
        let list;

        if (this.props.answerOptions !== null) {
            list = this.props.answerOptions.map((item, index) => {
                return {
                    id: item.examsquestoptions_id_selected2,
                    name: item.examsquestoption2,
                };
            });
        } else {
            list = this.props.opts.map((item, index) => {
                return {
                    id: item.examsquestoptions_id,
                    name: item.option_value,
                };
            });
        }

        this.setState({ list });
    }

    /**
     * Render Component
     * @returns {HTMLElement}
     */
    render() {
        const { list } = this.state;

        if (list == null) return;

        return (
            <ReactSortable
                animation={200}
                delayOnTouchStart={true}
                delay={2}
                list={list}
                setList={(list) => {
                    this.setState({ list });
                    this.props.updateMatchingWords(this.props.index, list);
                }}>
                {list.map((item, index) => (
                    <div key={index} className='word-to-match'>
                        {item.name}{' '}
                        <FontAwesomeIcon icon={faBars} color='#cdcdcd' />
                    </div>
                ))}
            </ReactSortable>
        );
    }
}

class ExamTimeLeft extends Component {
    // State
    state = {
        examHoursLeft: 0,
        examMinsLeft: 0,
        examSecsLeft: 0,
    };

    /**
     * Component Did Mount
     */
    componentDidMount() {
        const interval = setInterval(() => this.examRemainingTime(), 1000);
        return () => clearInterval(interval);
    }

    /**
     * Exam Remaining Time Clock
     */
    examRemainingTime = () => {
        const { examDetails } = this.props;
        const { Exams } = examDetails;
        const { exams_date, close_time } = Exams;
        const examEndingDatetime = Date.parse(
            exams_date + ' ' + close_time + ':00'
        );
        const timeLeft = examEndingDatetime - Date.now();
        this.setState({
            examHoursLeft: Math.floor((timeLeft / (1000 * 60 * 60)) % 24),
            examMinsLeft: Math.floor((timeLeft / 1000 / 60) % 60),
            examSecsLeft: Math.floor((timeLeft / 1000) % 60),
        });
    };

    /**
     * Render Component
     * @returns {HTMLElement}
     */
    render() {
        const { examHoursLeft, examMinsLeft, examSecsLeft } = this.state;

        return (
            <div
                style={{
                    display: 'flex',
                    fontSize: '14px',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    borderRadius: 35,
                    padding: '0 20px',
                }}
                className={this.props.dir == 1 ? 'ms-3' : 'me-3'}>
                <img src={timeLeft} alt='' width='40' className='me-2' />
                <span className='ms-1'>
                    <strong>{t('Time Remaining:')}</strong>
                    <br />
                    <span
                        style={{
                            color: '#EE3A81',
                            fontWeight: '400',
                        }}>
                        {' ' +
                            examHoursLeft +
                            ' ' +
                            t('Hours') +
                            ', ' +
                            examMinsLeft +
                            ' ' +
                            t('Minutes') +
                            ', ' +
                            examSecsLeft +
                            ' ' +
                            t('Seconds')}
                    </span>
                </span>
            </div>
        );
    }
}
