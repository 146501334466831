import React, { Component } from 'react'
import {
  Container,
  Card,
  Alert,
  Button,
  Row,
  Col,
  Table,
  Spinner,
  Modal,
} from 'react-bootstrap'
import { t } from '../../helpers/translation_helper'
import NavBar from '../_partials/NavBar/_NavBar'
import Header from '../_partials/Header/_Header'
import { connect } from 'react-redux'
import { withParams } from '../../helpers/params_helper'
import axios from 'axios'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUpRightFromSquare,
  faCircle,
  faComments,
  faExternalLink,
  faGlobe,
} from '@fortawesome/free-solid-svg-icons'
import {
  Black,
  Gray,
  VividMalachite,
  White,
} from '../../helpers/brand_colors_helper'

import './lesson-details.css'
import { Link } from 'react-router-dom'
import { getExtension } from '../../helpers/general_helpers'

// API URL
const apiURL = process.env.REACT_APP_API_URL

const portalURL = process.env.REACT_APP_PORTAL_URL
const imgDir = 'academic_subject_lessons'

/**
 * LessonDetails Component
 */
class LessonDetails extends Component {
  // State
  state = {
    lessonDetails: null,
    resourcesModal: false,
    selectedResource: null,
    academicSubjectLessons: null,
    academicSubjectLessonResources: [],
    academicLessonAttributes: [],
    curriculum: [],
    sectionGroup: 'All',
    formSections: [
      {
        uid: '1',
        sectionGroup: ['General', 'All'],
        active: true,
        completed: false,
        name: 'Basics',
        desc: null,
        data: [
          {
            uid: '1.1',
            sectionGroup: ['General', 'All'],
            active: false,
            completed: true,
            name: 'Lesson Focus',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
            showSaveBtn: true,
            saveAction: () => this.updateLesson(),
          },
          {
            uid: '1.2',
            sectionGroup: ['General', 'All'],
            active: false,
            completed: false,
            name: 'Central Idea',
            desc: null,
            image: require('../../assets/images/lesson/idea.png'),
            showSaveBtn: true,
            saveAction: () => this.updateLesson('asl_notes1'),
          },
          {
            uid: '1.3',
            sectionGroup: ['General', 'All'],
            active: false,
            completed: false,
            name: 'Lesson Resources',
            enum: 'external_resource',
            desc: '',
            image: require('../../assets/images/lesson/hyperlink.png'),
            saveAction: () => {
              this.insertLessonResource(
                this.state.academicSubjectLessonResources.findIndex(
                  (v, i) =>
                    v.aslr_type == 'external_resource' &&
                    v.editing
                )
              )
            },
          },
          // {
          //   uid: '1.4',
          //   sectionGroup: ['General', 'All'],
          //   active: false,
          //   completed: false,
          //   name: 'Teaching Resources',
          //   enum: 'teaching_resource',
          //   desc: [
          //     'What is it we want students to know, understand and be able to do? How are learning goals and success criteria co-constructed between teachers and students?',
          //   ],
          //   image: require('../../assets/images/lesson/hyperlink.png'),
          //   saveAction: () => {
          //     this.insertLessonResource(
          //       this.state.academicSubjectLessonResources.findIndex(
          //         (v, i) =>
          //           v.aslr_type == 'teaching_resource' &&
          //           v.editing
          //       )
          //     )
          //   },
          // },
          {
            uid: '1.5',
            sectionGroup: ['All'],
            active: false,
            completed: false,
            name: 'Transdisciplinary Theme',
            enum: 'transdisciplinary_theme',
            image: require('../../assets/images/lesson/line-chart.png'),
            showSaveBtn: true,
            saveAction: () =>
              this.saveMultiResources('transdisciplinary_theme'),
          },
        ],
      },
      {
        uid: '2',
        sectionGroup: ['General', 'All'],
        active: false,
        completed: false,
        name: 'Learning Goals',
        desc: '',
        data: [
          {
            uid: '2.1',
            sectionGroup: ['General', 'All'],
            active: false,
            completed: false,
            name: 'Approaches to learning',
            enum: 'approaches_to_learning',
            image: require('../../assets/images/lesson/learning-approaches.png'),
            desc: 'The Approaches to Learning in PYP include transdisciplinary themes that are important for students learning both inside and outside the classroom. These are the tools that students use across all subject areas to develop into successful students. Students get the opportunity to practice these skills and reflect on how they apply these skills to their everyday learning.',
            showSaveBtn: true,
            saveAction: () =>
              this.saveMultiResources('approaches_to_learning'),
          },
          {
            uid: '2.2',
            sectionGroup: ['General', 'All'],
            active: false,
            completed: false,
            name: 'Scope & Sequences / Competencies',
            enum: 'competency',
            image: require('../../assets/images/lesson/scope-sequence.png'),
            desc: null,
            showSaveBtn: true,
            saveAction: () => this.saveMultiResources('competency'),
          },
          {
            uid: '2.3',
            sectionGroup: ['General', 'All'],
            active: false,
            completed: false,
            name: 'Learning Goals & Success Criteria',
            enum: 'ongoing_reflection',
            image: require('../../assets/images/lesson/goal.png'),
            desc: [
              'What is it we want students to know, understand and be able to do?',
              'How are learning goals and success criteria co-constructed between teachers and students?',
            ],
            saveAction: () => {
              this.insertLessonResource(
                this.state.academicSubjectLessonResources.findIndex(
                  (v, i) =>
                    v.aslr_type == 'ongoing_reflection' &&
                    v.editing
                )
              )
            },
          },
          {
            uid: '2.4',
            sectionGroup: ['General', 'All'],
            active: false,
            completed: false,
            name: 'Key Vocab',
            enum: 'keyvocab',
            image: require('../../assets/images/lesson/hyperlink.png'),
            desc: [],
            saveAction: () => {
              this.insertLessonResource(
                this.state.academicSubjectLessonResources.findIndex(
                  (v, i) =>
                    v.aslr_type == 'keyvocab' && v.editing
                )
              )
            },
          },
          {
            uid: '2.5',
            sectionGroup: ['General', 'All'],
            active: false,
            completed: false,
            name: 'Skills',
            enum: 'learnskill',
            image: require('../../assets/images/lesson/hyperlink.png'),
            desc: [],
            saveAction: () => {
              this.insertLessonResource(
                this.state.academicSubjectLessonResources.findIndex(
                  (v, i) =>
                    v.aslr_type == 'learnskill' && v.editing
                )
              )
            },
          },
        ],
      },
      {
        uid: '3',
        sectionGroup: ['All'],
        active: false,
        completed: false,
        name: 'Learning Focus',
        desc: null,
        data: [
          {
            uid: '3.1',
            sectionGroup: ['All'],
            active: false,
            completed: false,
            name: 'Learner Profile',
            enum: 'learner_profile',
            image: require('../../assets/images/lesson/learner-profile.png'),
            desc: 'The aim of all IB programmes is to develop internationally minded people who, recognizing their common humanity and shared guardianship of the planet, help to create a better and more peaceful world. The IB learner profile represents 10 attributes valued by IB World Schools. We believe these attributes, and others like them, can help individuals and groups become responsible members of local, national and global communities.',
            showSaveBtn: true,
            saveAction: () =>
              this.saveMultiResources('learner_profile'),
          },
          {
            uid: '3.2',
            sectionGroup: ['All'],
            active: false,
            completed: false,
            name: 'Key Concepts',
            enum: 'lesson_key_concept',
            image: require('../../assets/images/lesson/key-concepts.png'),
            desc: 'It help students to engage in abstract thinking and conceptual thinking to cope-with tricky ideas. Key Concepts can help learners to deal with knowledge in transdisciplinary themes, which the IB intends to encourage. This kind of learning is not limited to factual concepts and content, helping students to engage with broader concepts that can motivate inquisitive, creative and critical thinking.',
            showSaveBtn: true,
            saveAction: () =>
              this.saveMultiResources('lesson_key_concept'),
          },
          {
            uid: '3.3',
            sectionGroup: ['All'],
            active: false,
            completed: false,
            name: 'Related Concepts',
            enum: 'related_concept',
            // related_concept_learn_area, related_concept_key_concept
            image: require('../../assets/images/lesson/related-concepts.png'),
            desc: [
              'A related concept deepens understanding of a key concept or a subject. As with key concepts some related concepts have relevance across other subjects and provide further opportunities to make connections across, between and beyond subjects. In contrast to the broad key concepts, related concepts are more narrowly focused.',
              'Select related concepts for the lesson. You can select related concepts by subjects or by key concepts.',
            ],
            showSaveBtn: true,
            saveAction: () =>
              this.saveMultiResources('related_concept'),
          },
          {
            uid: '3.4',
            sectionGroup: ['All'],
            active: false,
            completed: false,
            name: 'Lines of enquiry',
            enum: 'lines_of_enquiry',
            image: require('../../assets/images/lesson/inquiry.png'),
            desc: [
              'What lines of inquiry will define the scope of inquiry into the central idea?',
            ],
            saveAction: () => {
              this.insertLessonResource(
                this.state.academicSubjectLessonResources.findIndex(
                  (v, i) =>
                    v.aslr_type == 'lines_of_enquiry' &&
                    v.editing
                )
              )
            },
          },
        ],
      },
      {
        uid: '4',
        sectionGroup: ['All'],
        active: false,
        completed: false,
        name: 'Past & Future Learning',
        desc: null,
        data: [
          {
            uid: '4.1',
            sectionGroup: ['All'],
            active: false,
            completed: false,
            name: 'Prior Learning',
            enum: 'prior_learning',
            image: require('../../assets/images/lesson/prior-learning.png'),
            desc: [
              'Collaboratively capture your ideas for assessing and evidencing prior learning',
              'How are we assessing student’s prior knowledge, conceptual understanding and skills?',
              'How are we using data and evidence of prior learning to inform planning?',
              'How is our planning embracing student language profiles?',
            ],
            saveAction: () => {
              this.insertLessonResource(
                this.state.academicSubjectLessonResources.findIndex(
                  (v, i) =>
                    v.aslr_type == 'prior_learning' &&
                    v.editing
                )
              )
            },
          },
          {
            uid: '4.2',
            sectionGroup: ['All'],
            active: false,
            completed: false,
            name: 'Action',
            enum: 'action_prior_learning',
            image: require('../../assets/images/lesson/actions.png'),
            desc: [
              'What opportunities are there for building on prior learning to support potential student-initiated action?',
            ],
            showSaveBtn: true,
            saveAction: () =>
              this.saveMultiResources('action_prior_learning'),
          },
          {
            uid: '4.3',
            sectionGroup: ['All'],
            active: false,
            completed: false,
            name: 'Connections',
            enum: 'connection_transdisciplinary_past',
            image: require('../../assets/images/lesson/connections.png'),
            desc: [
              'Transdisciplinary and Past',
              'Connections to past future learning, inside and outside the programme of inquiry',
              'What connections are there to learning within and outside unit of inquiry?',
              'What opportunities are there for the students to develop conceptual understanding to support transfer of learning across, between and beyond subjects?',
              'How can we ensure that learning is purposeful and connects to local and global challenges and opportunities?',
            ],
            saveAction: () => {
              this.insertLessonResource(
                this.state.academicSubjectLessonResources.findIndex(
                  (v, i) =>
                    v.aslr_type ==
                    'connection_transdisciplinary_past' &&
                    v.editing
                )
              )
            },
          },
        ],
      },
      // {
      //   uid: '5',
      //   sectionGroup: ['General', 'All'],
      //   active: false,
      //   completed: false,
      //   name: 'Questions',
      //   desc: null,
      //   data: [
      //     {
      //       uid: '5.1',
      //       sectionGroup: ['General', 'All'],
      //       active: false,
      //       completed: false,
      //       name: 'Teacher Questions',
      //       enum: 'question_teacher',
      //       image: require('../../assets/images/lesson/teacher-question.png'),
      //       desc: [
      //         'What teacher questions and provocations will infirm the lines of inquiry?',
      //       ],
      //       saveAction: () => {
      //         this.insertLessonResource(
      //           this.state.academicSubjectLessonResources.findIndex(
      //             (v, i) =>
      //               v.aslr_type == 'question_teacher' &&
      //               v.editing
      //           )
      //         )
      //       },
      //     },
      //     {
      //       uid: '5.2',
      //       sectionGroup: ['General', 'All'],
      //       active: false,
      //       completed: false,
      //       name: 'Student Questions',
      //       enum: 'question_student',
      //       image: require('../../assets/images/lesson/student-question.png'),
      //       desc: [
      //         'What student’s questions, prior knowledge, existing theories, experiences and interests will inform the lines of inquiry?',
      //       ],
      //       saveAction: () => {
      //         this.insertLessonResource(
      //           this.state.academicSubjectLessonResources.findIndex(
      //             (v, i) =>
      //               v.aslr_type == 'question_student' &&
      //               v.editing
      //           )
      //         )
      //       },
      //     },
      //   ],
      // },
    ],
  }

  /**
   * Component Did Mount
   */
  componentDidMount() {
    this.getLessonDetails()
    this.getLessonAttributes()
    this.getCurriculum()
    let lessonData = this.props.location.state.lessonData
    this.setState({
      academicSubjectLessons: {
        asl_id: lessonData.asl_id,
        acs_id: lessonData.acs_id,
        as_id: lessonData.as_id,
        asl_name: lessonData.asl_name,
        asl_coef: lessonData.asl_coef,
        asl_code: lessonData.asl_code,
        center_id: lessonData.center_id,
        asl_period: lessonData.asl_period,
        asl_attach: lessonData.asl_attach,
        asl_link: lessonData.asl_link,
        asl_order: lessonData.asl_order,
        asl_sen_required: lessonData.asl_sen_required,
        asl_ict_usage: lessonData.asl_ict_usage,
        asl_afl: lessonData.asl_afl,
        asl_notes1: lessonData.asl_notes1,
        asl_notes2: lessonData.asl_notes2,
        asl_notes3: lessonData.asl_notes3,
        asl_type1: lessonData.asl_type1,
        asl_type2: lessonData.asl_type2,
        asl_theme: lessonData.asl_theme,
        asl_chat_blog: lessonData.asl_chat_blog,
      },
    })
  }

  /**
   * Get Lesson Details
   */
  getLessonDetails = async () => {
    const { authData } = this.props
    const { auth_key } = authData.loginData
    const { center_id } = authData.centerData
    const { asl_id } = this.props.params
    const params = {
      center_id,
      asl_id,
      auth_key: auth_key,
    }

    try {
      const res = await axios.get(
        apiURL + 'academic-subject-lessons/get-lesson-resources',
        {
          params,
        }
      )

      this.setState({
        academicSubjectLessonResources: res.data,
      })
    } catch (err) {
      toast.error('Something went wrong while fetching lesson details!')
      console.log(err)
    }
  }

  getLessonAttributes = async () => {
    this.setState({
      cat5DataLoaded: false,
    })

    const { authData } = this.props
    const { center_id } = authData.centerData
    const { auth_key } = authData.loginData

    try {
      const res = await axios.get(
        apiURL + 'academic-subject-lessons/get-lesson-attributes',
        {
          params: {
            // asl_id: lessonData.asl_id,
            center_id,
            auth_key,
          },
        }
      )

      if (res.status == 200) {
        this.setState({ academicLessonAttributes: res.data })
      }

      this.setState({
        cat5DataLoaded: true,
      })
    } catch (err) {
      toast.error(
        t('Something went wrong while fetching Lesson Attributes!')
      )
      console.log(err)
    }
  }

  getCurriculum = async () => {
    this.setState({
      curriculum: [],
    })
    const { authData, selectedChild } = this.props
    const { center_id } = authData.centerData
    const { auth_key } = authData.loginData
    const { lessonData } = this.props.location.state

    try {
      const res = await axios.get(
        apiURL + 'academic-subject-lessons/get-curriculum',
        {
          params: {
            center_id,
            auth_key,
            agegroup_id: selectedChild.agegroup_id
          },
        }
      )

      if (res.status == 200) {
        this.setState({
          curriculum: res.data,
        })
      }
    } catch (err) {
      toast.error(t('Something went wrong while fetching curriculum!'))
      console.log(err)
    }
  }

  /**
   * Handle Modal
   */
  handleModal = (selectedResource) => {
    this.setState({
      resourcesModal: !this.state.resourcesModal,
      selectedResource,
    })
  }

  renderThisFormSectionReadOnly = () => {
    let { formSectionActive, externalResource, type1Opts, type2Opts, academicSubjectLessons, image, image_prev, image_deleted } = this.state;
    if (academicSubjectLessons == null) {
      return null
    }
    let { asl_name, asl_coef, asl_code, center_id, asl_period, asl_attach, asl_link, asl_order, asl_sen_required, asl_ict_usage, asl_afl, asl_notes1, asl_notes2, sl_notes3, asl_type1, asl_type2, asl_theme, asl_chat_blog, } = academicSubjectLessons

    let transdisciplinary_theme = this.state.academicSubjectLessonResources.find((v, i) => v.aslr_type == 'transdisciplinary_theme')
    let transdisciplinary_theme_focus = this.state.academicSubjectLessonResources.filter((v, i) => v.aslr_type == 'transdisciplinary_theme_focus')


    let approaches_to_learning = []
    this.state.academicLessonAttributes.map((vvv, iii) => {
      if (vvv.ala_type == 'approaches_to_learning' && vvv.ala_parent_id == 0) {
        let allChildren = this.state.academicLessonAttributes.filter((vvvv, iiii) => vvvv.ala_parent_id == vvv.ala_id)
        let selectedChildren = []
        allChildren.map((vvvv, iiii) => {
          let find = this.state.academicSubjectLessonResources.find((vvvvv, iiiii) => ('approaches_to_learning' == vvvvv.aslr_type && vvvv.ala_id == vvvvv.aslr_type_id))
          if (find) {
            selectedChildren.push(find)
          }
        })
        if (selectedChildren.length > 0) {
          approaches_to_learning.push({
            title: vvv.ala_title,
            desc: vvv.ala_description_1,
            children: selectedChildren
          })
        }
      }
    })

    // competency
    let competency = []
    this.state.curriculum.map((vvv, iii) => {
      let allChildren = vvv.curriculum
      let selectedChildren = []
      allChildren.map((vvvv, iiii) => {
        let find = this.state.academicSubjectLessonResources.find((vvvvv, iiiii) => ('competency' == vvvvv.aslr_type && vvvv.curriculum_id == vvvvv.aslr_type_id))
        if (find) {
          selectedChildren.push(find)
        }
      })
      if (selectedChildren.length > 0) {
        competency.push({
          title: vvv.learnarea_desc || vvv.learnarea_code,
          children: selectedChildren
        })
      }
    })

    let related_concept_learn_area = []
    this.state.curriculum.map((vvv, iii) => {
      let findIndexSelectedData = this.state.academicSubjectLessonResources.findIndex((vvvv) => (vvvv.aslr_type_id == vvv.learnarea_id && vvvv.aslr_type == 'related_concept_learn_area'))
      let selectedChildren = []
      if (findIndexSelectedData !== -1) {
        selectedChildren = JSON.parse(this.state.academicSubjectLessonResources[findIndexSelectedData].aslr_desc)
      }
      if (selectedChildren.length > 0) {
        related_concept_learn_area.push({
          title: vvv.learnarea_desc,
          children: selectedChildren
        })
      }
    })

    let related_concept_key_concept = []
    this.state.academicLessonAttributes.map((vvv, iii) => {
      if (vvv.ala_type == 'lesson_key_concept' && vvv.ala_parent_id == 0) {

        let findIndexSelectedData = this.state.academicSubjectLessonResources.findIndex((vvvv) => (vvvv.aslr_type_id == vvv.ala_id && vvvv.aslr_type == 'related_concept_key_concept'))
        let selectedChildren = []
        if (findIndexSelectedData !== -1) {
          selectedChildren = JSON.parse(this.state.academicSubjectLessonResources[findIndexSelectedData].aslr_desc)
        }
        if (selectedChildren.length > 0) {
          related_concept_key_concept.push({
            title: vvv.ala_title,
            children: selectedChildren
          })
        }
      }
    })
    let action_prior_learning = []
    this.state.academicLessonAttributes.map((vvv, iii) => {
      let findIndexSelectedData = this.state.academicSubjectLessonResources.findIndex((vvvv) => (vvvv.aslr_type_id == vvv.ala_id && vvvv.aslr_type == 'action_prior_learning'))
      if (vvv.ala_type == 'action_prior_learning' && vvv.ala_parent_id == 0 && findIndexSelectedData !== -1) {
        action_prior_learning.push({
          title: vvv.ala_title,
          children: this.state.academicSubjectLessonResources[findIndexSelectedData].aslr_desc,
          desc: vvv.ala_description_1
        })
      }
    })

    return this.state.formSections.map((v, i) => {

      if (!v.sectionGroup.includes(this.state.sectionGroup)) {
        return null
      }

      return (
        <div className='lesson-view'>
          {v.data.map((vv, ii) => {

            if (!vv.sectionGroup.includes(this.state.sectionGroup)) {
              return null
            }

            let thisData = []
            if (vv.uid == '1.3' || vv.uid == '1.4' || vv.uid == '2.3' || vv.uid == '2.4' || vv.uid == '2.5' || vv.uid == '3.4' || vv.uid == '4.1' || vv.uid == '4.3' || vv.uid == '5.1' || vv.uid == '5.2') {
              thisData = this.state.academicSubjectLessonResources.filter((v, i) => v.aslr_type == vv.enum)
            } else if (vv.uid == '3.1') {
              this.state.academicLessonAttributes.map((vvv, iii) => {
                let findIndexSelectedData = this.state.academicSubjectLessonResources.findIndex((vvvv) => (vvvv.aslr_type_id == vvv.ala_id && vvvv.aslr_type == vv.enum))
                if (vvv.ala_type == vv.enum && vvv.ala_parent_id == 0 && findIndexSelectedData !== -1) {
                  thisData.push({
                    title: vvv.ala_title,
                    description: vvv.ala_description_1,
                  })
                }
              })
            } else if (vv.uid == '3.2') {
              this.state.academicLessonAttributes.map((vvv, iii) => {
                let findIndexSelectedData = this.state.academicSubjectLessonResources.findIndex((vvvv) => (vvvv.aslr_type_id == vvv.ala_id && vvvv.aslr_type == vv.enum))
                if (vvv.ala_type == vv.enum && vvv.ala_parent_id == 0 && findIndexSelectedData !== -1) {
                  thisData.push({
                    title: vvv.ala_title,
                    description: vvv.ala_description_2,
                    description2: vvv.ala_description_1,
                    description3: (findIndexSelectedData !== -1) ? this.state.academicSubjectLessonResources[findIndexSelectedData].aslr_desc : '',
                  })
                }
              })
            }

            return (
              <>
                {/* LESSON FOCUS */}
                {(vv.uid == '1.1') &&
                  (<Row>
                    <Col md={3}>
                      <img src={asl_attach ? portalURL + center_id + '/' + imgDir + '/' + asl_attach : require('../../assets/images/noimage.png')} alt={t('Preview')} style={{ width: '100%' }} />
                    </Col>
                    <Col>
                      <div className={'d-flex flex-column align-items-center'}>
                        <div className={'d-flex flex-row align-items-center'}>
                          <span className='lesson-title-sub px-10'>{asl_code}</span>
                          <span className={'px-10 border-left lesson-title'}>{asl_name}</span>
                          <a className='border-left px-10' href={asl_link} target="_blank">
                            <FontAwesomeIcon icon={faGlobe} color={VividMalachite} style={{ fontSize: 20 }} />
                          </a>
                          <a className='border-left px-10' href={'to the blog page'} target="_blank">
                            <FontAwesomeIcon icon={faComments} color={VividMalachite} style={{ fontSize: 20 }} />
                          </a>
                        </div>
                        <div className='lesson-title-sub2'>{asl_type1} | {asl_type2}</div>
                        <div className='lesson-title-sub2'>Periods : {asl_period}</div>
                        <div className='lesson-title-sub2'>Theme : {asl_theme}</div>
                        <div className='lesson-title-sub2'>{asl_notes1}</div>
                      </div>
                    </Col>
                    {transdisciplinary_theme &&
                      <Col md={3}>
                        <div className='div-box-theme'>
                          {transdisciplinary_theme && this.state.academicLessonAttributes.map((vvv, iii) => {
                            return (vvv.ala_id == transdisciplinary_theme.aslr_type_id) &&
                              <div className='d-flex flex-row align-items-center'>
                                <div className='div_image'>
                                  <img src={portalURL + vvv.center_id + '/academic_lesson_attributes/' + vvv.ala_description_2} className="image" />
                                </div>
                                <div className='heading'>{vvv.ala_title}</div>
                              </div>

                          })}

                          <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                            {(transdisciplinary_theme) && this.state.academicLessonAttributes.map((vvv, iii) => {
                              let academicSubjectLessonResourcesFind = this.state.academicSubjectLessonResources.find((vvvv, iiii) => {
                                return (vvvv.aslr_type == 'transdisciplinary_theme_focus' && vvvv.aslr_type_id == vvv.ala_id)
                              })
                              return (vvv.ala_type == 'lesson_focus' && academicSubjectLessonResourcesFind)
                                ? (<div key={iii} className="d-flex flex-row justify-content-center">
                                  <FontAwesomeIcon icon={faCircle} color={Gray} style={{ fontSize: 12, padding: '8px' }} />
                                  <span className='title'>{vvv.ala_title}</span>
                                </div>) : null
                            })}
                          </div>
                        </div>
                      </Col>
                    }
                  </Row>)
                }


                {/* Lesson Resources */}
                {(vv.uid == '1.3') && thisData.length > 0 &&
                  <>
                    <Row>
                      <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id={'form_section_' + vv.uid}>
                        <div>
                          <img className='image' src={(vv.image) ? vv.image : null} />
                          <span className='heading'>{vv.name}</span>
                        </div>
                        <div>
                        </div>
                      </div>
                      <Col>
                        <div className='d-flex flex-row flex-wrap justify-content-center' style={{ marginTop: '10px' }}>
                          {thisData.map((vvv, iii) => {
                            return (<div
                              onClick={() => vvv.aslr_url_type == 'youtube' ? this.handleModal(vvv) : window.open(vvv.aslr_url || portalURL + vvv.center_id + '/academic_subject_lesson_resources/' + vvv.aslr_attachment, '_blank')}
                              key={iii}
                              className='d-flex flex-column justify-content-center align-items-center box-shadow br-20 p-2 m-2'
                              style={{ cursor: 'pointer', width: '10%', }}>
                              <img src={require('../../assets/images/' + vvv.aslr_url_type + '.png')} style={{ width: '20%', }} />
                              <span className='lesson-title-sub text-center'>
                                {vvv.aslr_title}
                              </span>
                            </div>)
                          })}
                        </div>
                      </Col>
                    </Row>
                  </>
                }


                {/* 1.4 Teaching Resources */}
                {/* 2.3 Learning Goals & Success Criteria */}
                {/* 2.4 Key Vocab */}
                {/* 2.5 Key Skills */}
                {/* 3.4 Lines of Inquiry */}
                {/* 4.1 Prior Learning */}
                {/* 4.3 Connections */}
                {/* 5.1 Teacher Questions */}
                {/* 5.2 Student Questions */}
                {(['1.4', '2.3', '2.4', '2.5', '3.4', '4.1', '4.3', '5.1', '5.2'].includes(vv.uid)) && thisData.length > 0 &&
                  <>
                    <Row>
                      <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id={'form_section_' + vv.uid}>
                        <div>
                          <img className='image' src={(vv.image) ? vv.image : null} />
                          <span className='heading'>{vv.name}</span>
                        </div>
                        <div>
                        </div>
                      </div>
                      <Col md={12}>
                        {(vv.desc.length > 0) && <Alert variant='secondary' className='mb-1'>
                          {vv.desc.map((vvv) => {
                            return <div>{vvv}</div>
                          })}
                        </Alert>}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                          {thisData.map((vvv, iii) => {
                            return (<div key={iii} className="d-flex flex-row justify-content-center align-items-center listgroup">
                              <span className='title'>{vvv.aslr_title}</span>
                            </div>)
                          })}
                        </div>
                      </Col>
                    </Row>
                  </>
                }

                {/* Approaches to learning */}
                {(vv.uid == '2.1') && approaches_to_learning.length > 0 &&
                  <Row style={{ marginBottom: '20px' }}>
                    <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id={'form_section_' + vv.uid}>
                      <div>
                        <img className='image' src={(vv.image) ? vv.image : null} />
                        <span className='heading'>{vv.name}</span>
                      </div>
                      <div>
                      </div>
                    </div>
                    <Col md={12}>
                      <Alert variant='secondary' className='mb-1'>
                        {vv.desc}
                      </Alert>
                    </Col>
                    <Col md="12">
                      {
                        approaches_to_learning.map((vvv, iii) => {
                          return <div className='div-box-theme'>
                            <div className='d-flex flex-row' style={{ cursor: 'pointer' }}>
                              <div className='heading'>{vvv.title}</div>
                            </div>
                            <div style={{ fontSize: '13px', color: Black, opacity: '0.7' }}>{vvv.ala_description_1}</div>
                            {(vvv.children.length > 0)
                              && <div style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: Gray, marginTop: '5px', paddingTop: '5px', width: '100%' }}>
                                {vvv.children.map((vvvv, iiii) => {
                                  return <div className='d-flex flex-row justify-content-between' style={{ color: Black, opacity: 0.7 }}>
                                    <div style={{ marginTop: '10px', fontSize: '14px', color: Black, opacity: 0.7 }}><span style={{ fontWeight: '600' }}>{vvvv.aslr_title}</span> : {vvvv.aslr_desc}</div>
                                  </div>
                                })}
                              </div>
                            }
                          </div>
                        })
                      }
                    </Col>
                  </Row>
                }

                {/* Scope & Sequences */}
                {(vv.uid == '2.2') && competency.length > 0 &&
                  <Row style={{ marginBottom: '20px' }}>
                    <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id={'form_section_' + vv.uid}>
                      <div>
                        <img className='image' src={(vv.image) ? vv.image : null} />
                        <span className='heading'>{vv.name}</span>
                      </div>
                      <div>
                      </div>
                    </div>
                    <Col md="12">
                      {competency.map((vvv, iii) => {
                        return <div className='div-box-theme'>
                          <div className='d-flex flex-row' style={{ cursor: 'pointer' }} >
                            <div className='heading'>{vvv.title}</div>
                          </div>
                          {(vvv.children.length > 0)
                            && <div style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: Gray, marginTop: '5px', paddingTop: '5px', width: '100%' }}>
                              {vvv.children.map((vvvv, iiii) => {
                                return <div className='d-flex flex-row justify-content-between' style={{ color: Black, opacity: 0.7 }}>
                                  <div style={{ marginTop: '10px', fontSize: '14px', color: Black, opacity: 0.7 }}>{vvvv.aslr_title}</div>
                                </div>
                              })}
                            </div>
                          }
                        </div>
                      })}

                    </Col>
                  </Row>
                }

                {/* Learner Profile */}
                {(vv.uid == '3.1') && thisData.length > 0 &&
                  <Row>
                    <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id={'form_section_' + vv.uid}>
                      <div>
                        <img className='image' src={(vv.image) ? vv.image : null} />
                        <span className='heading'>{vv.name}</span>
                      </div>
                      <div>
                      </div>
                    </div>
                    <Col md={12}>
                      <Alert variant='secondary' >{t(vv.desc)}</Alert>
                    </Col>
                    <Col md={12} className='d-flex flex-row flex-wrap'>
                      {thisData.map((vvv, iii) => {
                        return <div className='div-box-theme cursor-pointer' style={{ flex: 'none', width: '47%' }}
                        >
                          <div className='heading'>{vvv.title}</div>
                          <div className='description'>{vvv.description}</div>
                        </div>
                      })}
                    </Col>
                  </Row>
                }

                {/* Key Concepts */}
                {(vv.uid == '3.2') && thisData.length > 0 &&
                  <Row>
                    <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id={'form_section_' + vv.uid}>
                      <div>
                        <img className='image' src={(vv.image) ? vv.image : null} />
                        <span className='heading'>{vv.name}</span>
                      </div>
                      <div>
                      </div>
                    </div>
                    <Col md={12}>
                      <Alert variant='secondary' >{t(vv.desc)}</Alert>
                    </Col>
                    <Col md={12} className='d-flex flex-row flex-wrap'>
                      {thisData.map((vvv, iii) => {
                        return <div className='div-box-theme' style={{ flex: 'none', width: '47%' }}>
                          <div className='heading'>
                            {vvv.title}
                          </div>
                          <div className='description'>{vvv.description}</div>
                          <div style={{ width: '100%' }}>
                            <div style={{ fontWeight: '600' }}>{vvv.description2}</div>
                            <div>{vvv.description3}</div>
                          </div>
                        </div>
                      })}
                    </Col>
                  </Row>
                }

                {/* Related Concepts */}
                {(vv.uid == '3.3') && (related_concept_learn_area.length > 0 || related_concept_key_concept.length > 0) &&
                  <>
                    <Row style={{ marginBottom: '20px' }}>
                      <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id={'form_section_' + vv.uid}>
                        <div>
                          <img className='image' src={(vv.image) ? vv.image : null} />
                          <span className='heading'>{vv.name}</span>
                        </div>
                        <div>
                        </div>
                      </div>
                      <Col md={12}>
                        <Alert variant='secondary' >
                          {vv.desc.map((vvv) => {
                            return <div>{vvv}</div>
                          })}
                        </Alert>
                      </Col>
                      {related_concept_learn_area.length > 0 &&
                        <Col md="6">
                          <div className='div-box-theme' style={{ marginRight: 0 }}>
                            <div className='d-flex flex-row' style={{ cursor: 'pointer' }}>
                              <div className='heading'>By Subject</div>
                            </div>
                            <div style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: Gray, marginTop: '5px', paddingTop: '5px', width: '100%' }}>
                              <div className='d-flex flex-column justify-content-between' style={{}}>
                                {related_concept_learn_area.map((vvv, iii) => {

                                  return <div style={{ marginTop: '10px', fontSize: '14px', color: Black, opacity: 0.7 }}>
                                    <span style={{ fontWeight: '600' }}>{vvv.title}</span>
                                    <div className='d-flex flex-row flex-wrap'>
                                      {vvv.children.map((vvvv, iiii) => {
                                        return <span style={{
                                          padding: '5px 10px',
                                          margin: '3px 5px',
                                          background: VividMalachite,
                                          color: White,
                                          borderRadius: '20px',
                                        }}>{vvvv}</span>
                                      })}
                                    </div>
                                  </div>
                                })}
                              </div>
                            </div>
                          </div>
                        </Col>
                      }
                      {related_concept_key_concept.length > 0 &&
                        <Col md="6">
                          <div className='div-box-theme' style={{ marginRight: 0 }}>
                            <div className='d-flex flex-row' style={{ cursor: 'pointer' }}>
                              <div className='heading'>By Key concept</div>
                            </div>
                            <div style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: Gray, marginTop: '5px', paddingTop: '5px', width: '100%' }}>
                              <div className='d-flex flex-column justify-content-between' style={{}}>
                                {related_concept_key_concept.map((vvv, iii) => {

                                  return <div style={{ marginTop: '10px', fontSize: '14px', color: Black, opacity: 0.7 }}>
                                    <span style={{ fontWeight: '600' }}>{vvv.title}</span>
                                    <div className='d-flex flex-row flex-wrap'>
                                      {vvv.children.map((vvvv, iiii) => {
                                        return <span style={{
                                          padding: '5px 10px',
                                          margin: '3px 5px',
                                          background: VividMalachite,
                                          color: White,
                                          borderRadius: '20px',
                                        }}>{vvvv}</span>
                                      })}
                                    </div>
                                  </div>
                                })}
                              </div>
                            </div>
                          </div>
                        </Col>
                      }

                    </Row>
                  </>
                }

                {/* Action */}
                {(vv.uid == '4.2') && action_prior_learning.length > 0 &&
                  <Row>
                    <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id={'form_section_' + vv.uid}>
                      <div>
                        <img className='image' src={(vv.image) ? vv.image : null} />
                        <span className='heading'>{vv.name}</span>
                      </div>
                      <div>
                      </div>
                    </div>
                    <Col md={12}>
                      <Alert variant='secondary' className='mb-1'>
                        {vv.desc.map((vvv) => {
                          return <div>{vvv}</div>
                        })}
                      </Alert>
                    </Col>
                    <Col md={12} className='d-flex flex-row flex-wrap'>
                      {action_prior_learning.map((vvv, iii) => {
                        return <div className='div-box-theme' style={{ flex: 'none', width: '47%' }}>
                          <div className='heading'>
                            {vvv.title}
                          </div>
                          <div className='description'>{vvv.desc}</div>
                          <div style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: Gray, marginTop: '5px', paddingTop: '5px', width: '100%' }}>
                            <div>{vvv.children}</div>
                          </div>
                        </div>
                      })}
                    </Col>

                  </Row>
                }

              </>
            )
          })
          }
        </div>
      )

    })

  }

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    // Language Direction
    const { lang_orientation: dir } = this.props.defaultLanguage

    // State
    const { lessonDetails, resourcesModal, selectedResource } = this.state

    // Resources Link
    let resourcesLink
    console.log('selectedResource', selectedResource)
    if (selectedResource && selectedResource.aslr_url_type == 'youtube') {
      resourcesLink = selectedResource.aslr_url
      const resourceArr = resourcesLink.split('=')
      resourcesLink = 'https://youtube.com/embed/' + resourceArr[1]
    }

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Lesson Details')} backBtn={true} />
            <div className='grey-section lesson-plan '>
              <div style={{ backgroundColor: White, marginLeft: 10, marginRight: 10, borderRadius: '20px', flex: 1, padding: '20px' }}>
                {this.renderThisFormSectionReadOnly()}
              </div>
            </div>
          </div>
        </div>
        <Modal
          size='lg'
          show={resourcesModal}
          onHide={() => this.handleModal()}>
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedResource &&
                selectedResource.resources_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {resourcesModal &&
              <iframe src={selectedResource && resourcesLink} title='Resource' width='100%' height='450'></iframe>
            }
          </Modal.Body>
        </Modal>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedChild: state.selectedChild.data,
  languages: state.language.languages,
  defaultLanguage: state.language.defaultLanguage,
})

export default connect(mapStateToProps, null)(withParams(LessonDetails))
