import { Component } from 'react'
import {
  Button,
  Col,
  Container,
  Row,
  Image,
  ListGroup,
  Spinner,
  Offcanvas
} from 'react-bootstrap'
import { connect } from 'react-redux'
import NavBar from '../_partials/NavBar/_NavBar'
import Timeline from './Timeline'
import './dashboard.css'
import { unsetAuthentication } from '../../redux/slices/authSlice'
import { setSelectedChild } from '../../redux/slices/selectedChildSlice'
import { setDefaultLanguage } from '../../redux/slices/langSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff, faBell, faSearch, faChevronLeft, faChevronRight, faLanguage, faCheckCircle, faCalendarAlt, faCalendarDay, faTable, faChartBar, faCalendar, faAngleRight, faAngleLeft, } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { Modal, Form } from 'react-bootstrap'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import person1 from '../../assets/images/person-1.png'
import person2 from '../../assets/images/person-2.png'
import person3 from '../../assets/images/person-3.png'
import person4 from '../../assets/images/person-4.png'
import sub1 from '../../assets/images/sub-1.png'
import sub2 from '../../assets/images/sub-2.png'
import sub3 from '../../assets/images/sub-3.png'
import sub4 from '../../assets/images/sub-4.png'
import u1 from '../../assets/images/u-1.png'
import u2 from '../../assets/images/u-2.png'
import u3 from '../../assets/images/u-3.png'
import axios from 'axios'
import { t } from '../../helpers/translation_helper'
import Header from '../_partials/Header/_Header'
import { NavLink, Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Timetable from './Timetable'
import { Black } from '../../helpers/brand_colors_helper'
import GeneralCard from '../../helpers/GeneralCard'

// API URL
const apiURL = process.env.REACT_APP_API_URL

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL

let controller // For axios abort controller

/**
 * Dashboard Component
 */
class Dashboard extends Component {
  /**
   * @constructor
   * @param {any} props
   */
  constructor(props) {
    super(props)

    // MISSING UT TYPES
    // Exam Retake
    // Medication Consent
    // 'BMI', 'Medicine Dose', 'Academic Lesson', 'Pastoral Care', 'Daily Activity',  'Registration', 'Engage'

    const defaultUtTypes = [
      {
        label: "Notification",
        value: "Notification",
        picture: require('../../assets/images/user-timeline/notification.png'),
        active: true,
      },
      {
        label: "Event",
        value: "Event",
        picture: require('../../assets/images/user-timeline/event.png'),
        active: true,
      },
      {
        label: "Newsletter",
        value: "Newsletter",
        picture: require('../../assets/images/user-timeline/newsletter.png'),
        active: true,
      },
      {
        label: "Media",
        value: "Media",
        picture: require('../../assets/images/user-timeline/photos.png'),
        active: true,
      },
      {
        label: "Video",
        value: "Video",
        picture: require('../../assets/images/user-timeline/videos.png'),
        active: true,
      },
      {
        label: "Note",
        value: "Note",
        picture: require('../../assets/images/user-timeline/attachment-pink.png'),
        active: true,
      },
      {
        label: "Online Class",
        value: "Online Class",
        picture: require('../../assets/images/user-timeline/online-class.png'),
        active: true,
      },
      {
        label: "Resources",
        value: "Resources",
        picture: require('../../assets/images/user-timeline/download.png'),
        active: true,
      },
      {
        label: "Policy",
        value: "Policy",
        picture: require('../../assets/images/user-timeline/policy.png'),
        active: true,
      },
      {
        label: "Incident Report",
        value: "Incident Report",
        picture: require('../../assets/images/user-timeline/nurse-2.png'),
        active: true,
      },
      {
        label: "Poll",
        value: "Poll",
        picture: require('../../assets/images/user-timeline/poll.png'),
        active: true,
      },
      {
        label: "Survey",
        value: "Survey",
        picture: require('../../assets/images/user-timeline/survey.png'),
        active: true,
      },
      {
        label: "Observation",
        value: "Assessment",
        picture: require('../../assets/images/user-timeline/grade.png'),
        active: true,
      },
      {
        label: "Monthly Observation",
        value: "CheckList",
        picture: require('../../assets/images/user-timeline/grade.png'),
        active: true,
      },
      {
        label: "Agenda",
        value: "Agenda",
        picture: require('../../assets/images/user-timeline/agenda.png'),
        active: true,
      },
      {
        label: "Assignment",
        value: "Assignment",
        picture: require('../../assets/images/user-timeline/assignment.png'),
        active: true,
      },
      {
        label: "Daily Report",
        value: "Daily Report",
        picture: require('../../assets/images/user-timeline/daily-report.png'),
        active: true,
      },
      {
        label: "Exam",
        value: "Exam",
        picture: require('../../assets/images/user-timeline/exam.png'),
        active: true,
      },
      {
        label: "Homework",
        value: "Online Homework",
        picture: require('../../assets/images/user-timeline/exam.png'),
        active: true,
      },
      {
        label: "Activity",
        value: "Academic Activity",
        picture: require('../../assets/images/user-timeline/activity.png'),
        active: true,
      },
      {
        label: "Result",
        value: "Result",
        picture: require('../../assets/images/user-timeline/awards.png'),
        active: true,
      },
    ]

    this.state = {
      utWorking: false,
      startDate: '',
      endDate: '',
      utTypes: defaultUtTypes,
      selectedUtTypes: defaultUtTypes,
      userTimelineData: [],
      offset: 0,
      loadMore: false,
      subjects: null,
      // calendarList: null
      calendarList: [],
      examsList: [],
      showUT: false,
    }
  }

  /**
   * Component Did Mount
   */
  componentDidMount() {
    this.getSubjects()
    this.getCalendar()
    this.getExamsCalendar()
  }

  /**
   * Handle Selected User Timeline Types
   * @param {array} types Array of user timeline types.
   */
  handleSelectUtTypes = (types) => {
    this.setState({
      selectedUtTypes: types,
    })
  }

  /**
   * Handle Date Range
   * @param {string} dateType Name of the date field in state
   * @param {any} date Date
   */
  handleUtDateRange = (dateType, date) => {
    this.setState({
      [dateType]: date,
    })
  }

  /**
   * Get Timeline
   * @param {boolean} reset Reset the filter
   */
  getTimeline = async (reset = false) => {
    if (this.state.finishedData || this.state.loadMore) {
      return false
    }
    this.setState({
      loadMore: true,
    }, async () => {

      if (this.state.offset === 0) {
        this.setState({ utWorking: true })
      }

      if (this.getTimelineReq) { this.getTimelineReq.abort(); }
      this.getTimelineReq = new AbortController();

      const { selectedUtTypes, startDate, endDate, userTimelineData } =
        this.state
      const { authData, selectedChild } = this.props
      const { class_id, child_id } = selectedChild
      const { center_id, center_timezone } = authData.centerData
      const { user_id, auth_key } = authData.loginData

      const fData = new FormData()
      fData.append('user_id', user_id)
      fData.append('center_id', center_id)
      fData.append('center_timezone', center_timezone)
      fData.append('class_id', class_id)
      fData.append('child_id', child_id)
      fData.append('activeFilterDateFrom', startDate ? moment(startDate).format('YYYY-MM-DD') + ' 00:00:00' : 'null')
      fData.append('activeFilterDateTo', endDate ? moment(endDate).format('YYYY-MM-DD') + ' 23:59:59' : 'null')
      fData.append('selectedTimelineTypes', JSON.stringify(selectedUtTypes.map((item) => { return { ut_type: item.value, active: true } })))
      fData.append('offset', this.state.offset)

      try {
        const req = await axios.post(apiURL + 'user-timeline', fData, {
          params: {
            auth_key: auth_key,
          },
          signal: this.getTimelineReq.signal,
        })

        if (req.status === 200) {
          let data = []
          if (reset) {
            data = req.data
          } else {
            data = [...userTimelineData, ...req.data]
          }

          this.setState({
            utWorking: false,
            userTimelineData: data,
            offset: this.state.offset + 10,
            loadMore: false,
            finishedData: (req.data.length <= 0) ? true : false
          })
          // setTimeout(() => {
          //     if (document.querySelector('.timeline-reel')) {
          //         document
          //             .querySelector('.timeline-reel')
          //             .setAttribute(
          //                 'style',
          //                 'height:' +
          //                     (document.querySelector(
          //                         '.page-content-inner'
          //                     ).offsetHeight -
          //                         60) +
          //                     'px'
          //             )
          //     }
          // }, 1000)
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log('Request canceled', err.message)
        } else {
          toast.error('Something went wrong while fetching news feed!')
          console.log(err)
        }
      }
    })
  }

  handleOpenUT = () => {
    this.setState({
      showUT: true,
      startDate: '',
      endDate: '',
      offset: 0,
      finishedData: false,
      selectedUtTypes: this.state.utTypes,
    }, () => this.getTimeline(true))

  }

  handleCloseUT = () => {
    this.setState({
      showUT: false,
      userTimelineData: [],
    })
  }

  /**
   * Get Subjects
   */
  getSubjects = async () => {
    this.setState({ subjects: null })
    const { authData, selectedChild } = this.props
    const { class_id, class_theme, agegroup_id } = selectedChild
    const { center_id } = authData.centerData
    const { auth_key } = authData.loginData

    const params = {
      class_id,
      center_id,
      agegroup_id,
      class_theme,
      auth_key: auth_key,
    }

    try {
      const res = await axios.get(
        apiURL + 'academic-subject-lessons/get-subjects',
        {
          params,
        }
      )

      this.setState({
        subjects: res.data.data,
      })
    } catch (err) {
      toast.error('Something went wrong while fetching subjects!')
      console.log(err)
    }
  }

  /**
   * Render Subjects SLides
   * @returns {HTMLElement} Renders subjects slides
   */
  renderSubjects = () => {
    const { subjects } = this.state
    const subjectsItems = subjects.map((v, i) => {

      let data = {
        allData: v,
        name: v.acs_name,
        code: v.acs_code,
        text1: (v.parent_acs_name) ? 'Focus:' : null,
        text2: v.parent_acs_name,
        description: v.acs_desc,
        // linkTo: '/subject/lessons/' + v.acs_name,
        // linkToStates: { as_id: v.as_id, acs_id: v.acs_id, ab_id: v.ab_id, },
        linkTo: '/subject/' + v.acs_id + '/' + v.acs_name,
        cardImage: (v.acs_image) ? portalURL + v.center_id + '/academic_classmain_subject/' + v.acs_image : require('../../assets/images/noimage.png'),
        extraTexts: [
          { type: 'url', name: 'URL', value: v.acs_url, iconName: null, image: require('../../assets/images/hyperlink.png'), iconColor: Black, onClick: () => { } },
          { type: 'text', name: 'Type', value: (v.acs_mandatory == 1) ? 'Mandatory' : 'Elective', icon: null, image: require('../../assets/images/asterisk.png'), iconColor: Black, onClick: () => { } }
        ]
      }
      return <GeneralCard data={data} key={i} />

      // let image = null

      // if (item.acs_image) {
      //   image =
      //     portalURL +
      //     item.center_id +
      //     '/academic_classmain_subject/' +
      //     item.acs_image
      // }

      // return (
      //   <div className='three-slide' key={index}>
      //     <Link to={'/subject/' + item.acs_id + '/' + item.acs_name}>
      //       <div className='slide-inner'>
      //         <div
      //           className='subject-image'
      //           style={{
      //             backgroundImage: `url(${image || u2})`,
      //           }}></div>
      //         <h6>{item.acs_name}</h6>
      //       </div>
      //     </Link>
      //   </div>
      // )
    })
    return subjectsItems
  }

  /**
   * Reset Filter
   */
  resetFilter = () => {
    this.setState(
      {
        startDate: '',
        endDate: '',
        offset: 0,
        finishedData: false,
        selectedUtTypes: this.state.utTypes,
      },
      () => this.getTimeline(true)
    )
  }

  applyFilter = () => {
    this.setState(
      {
        offset: 0,
        finishedData: false
      },
      () => this.getTimeline(true)
    )
  }

  /**
    * Get Calendar
    */
  getCalendar = async () => {
    this.setState({
      calendarList: null,
    })
    const { auth_key, center_id } = this.props.authData.loginData
    const { class_id } = this.props.selectedChild

    let request_params = {
      center_id,
      class_id,
      auth_key,
      date: moment().format('YYYY-MM-DD'),
    }

    try {
      const res = await axios.get(
        apiURL + 'center-calendar/get-center-calendar-day',
        {
          params: request_params,
        }
      )

      this.setState({
        calendarList: res.data,
      })

    } catch (err) {
      toast.error('Something went wrong while fetching calendar!')
      console.log(err)
    }
  }

  /**
  * Get Exams Calendar
  */
  getExamsCalendar = async () => {
    this.setState({
      examsList: null,
    })
    const { auth_key, center_id } = this.props.authData.loginData
    const { class_id } = this.props.selectedChild

    let request_params = {
      center_id,
      class_id,
      auth_key,
      date: moment().format('YYYY-MM-DD'),
    }

    try {
      const res = await axios.get(
        apiURL + 'dashboard/get-exams-calendar-day',
        {
          params: request_params,
        }
      )

      this.setState({
        examsList: res.data.data,
      })

    } catch (err) {
      toast.error('Something went wrong while fetching exams calendar!')
      console.log(err)
    }
  }

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    // Language Direction
    const { lang_orientation: dir } = this.props.defaultLanguage

    // State
    const {
      utWorking,
      startDate,
      endDate,
      utTypes,
      selectedUtTypes,
      userTimelineData,
      loadMore,
    } = this.state

    // Slick Button Component
    const SlickButton = ({
      currentSlide,
      slideCount,
      children,
      ...props
    }) => (
      <FontAwesomeIcon
        {...props}
        icon={props.dir == 'next' ? faChevronRight : faChevronLeft}
        color='#EE3A81'
      />
    )

    // Slick General Settings
    const generalSliderSettings = {
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
      nextArrow: <SlickButton dir='next' />,
      prevArrow: <SlickButton dir='prev' />,
    }

    // Slicer one additional settings
    const oneSliderSettings = {
      ...generalSliderSettings,
      slidesToShow: 4,
    }

    // Slicer two additional settings
    const twoSliderSettings = {
      ...generalSliderSettings,
      slidesToShow: 4,
    }

    // Slicer three additional settings
    const threeSliderSettings = {
      ...generalSliderSettings,
      slidesToShow: 4,
    }

    return (
      <>
        <Container fluid>
          <div id='iedu-layout'>
            <NavBar />
            <div id='page-content' style={{ padding: (dir == 1) ? '20px 118px 20px 10px' : '20px 10px 20px 118px' }}>
              <div className='page-content-inner'>
                <Header func={[this.resetFilter, this.getSubjects]} />
                <Timetable />
                <div className='slider-wrap one-slider no-one-slider'>
                  {/* <Slider {...oneSliderSettings}> */}
                  <div className='one-slide hs-1'>
                    <Link to='/class-blog'>
                      <div className='slide-inner'>
                        <h3>{t('CLASS BLOG')}</h3>
                        <img src={person2} alt='' />
                      </div>
                    </Link>
                  </div>
                  <div className='one-slide hs-3'>
                    <Link to='/homework'>
                      <div className='slide-inner'>
                        <h3>{t('HOMEWORK')}</h3>
                        <img src={person3} alt='' />
                      </div>
                    </Link>
                  </div>
                  <div className='one-slide hs-4'>
                    <Link to='/exams'>
                      <div className='slide-inner'>
                        <h3>{t('EXAM HALL')}</h3>
                        <img src={person4} alt='' />
                      </div>
                    </Link>
                  </div>
                  <div className='one-slide hs-2'>
                    <Link to='/tasks-list'>
                      <div className='slide-inner'>
                        <h3>{t('TASKS LIST')}</h3>
                        <img src={person1} alt='' />
                      </div>
                    </Link>
                  </div>
                  {/* </Slider> */}
                </div>
                <Row>
                  <Col md={6} style={{ paddingLeft: 18 }}>
                    <div className='widget-box' style={{
                      marginBottom: 23
                    }}>
                      <Link to='/calendar' className='widget-box-header'>
                        <Row>
                          <Col md={1} className='pe-0' style={{ textAlign: dir == 1 ? 'left' : 'right' }}>
                            <FontAwesomeIcon icon={faCalendar} style={{ color: '#fff', marginRight: 7 }} />
                          </Col>
                          <Col style={{ textAlign: dir == 1 ? 'right' : 'left', fontWeight: 700, color: '#fff' }}>{moment().format('dddd, D MMM YYYY')}</Col>
                          <Col md={'auto'}>
                            <FontAwesomeIcon icon={dir == 1 ? faChevronLeft : faChevronRight} style={{ color: '#fff', marginRight: 7 }} />
                          </Col>
                        </Row>
                      </Link>
                      <div className='widget-box-body'>
                        {!this.state.calendarList &&
                          <div style={{ paddingTop: 50, textAlign: 'center' }}>
                            <Spinner animation="grow" />
                          </div>
                        }
                        {this.state.calendarList?.length == 0 &&
                          <div style={{ paddingTop: 50, textAlign: 'center' }}>
                            {t('No Data Found!')}
                          </div>
                        }
                        {this.state.calendarList?.length > 0 &&
                          <div className='widget-box-list'>
                            {this.state.calendarList.map((item, index) => {
                              const body = item.body.replace(/(<([^>]+)>)/gi, "")
                              return (
                                <div key={index} className={dir == 1 ? 'widget-box-list-item-ar' : 'widget-box-list-item'}>
                                  {item.title} - {body.length > 50 ? body.substring(0, 50) + '...' : body}
                                </div>
                              )
                            }
                            )}
                          </div>
                        }
                      </div>
                    </div>
                  </Col>
                  <Col md={6} style={{ paddingLeft: 18 }}>
                    <div className='widget-box' style={{
                      marginBottom: 23
                    }}>
                      <Link to='/exam-calendar' className='widget-box-header'>
                        <Row>
                          <Col md={1} className='pe-0' style={{ textAlign: dir == 1 ? 'left' : 'right' }}>
                            <FontAwesomeIcon icon={faCalendar} style={{ color: '#fff', marginRight: 7 }} />
                          </Col>
                          <Col style={{ textAlign: dir == 1 ? 'right' : 'left', fontWeight: 700, color: '#fff' }}>{t("Today's Exams Calendar")}</Col>
                          <Col md={'auto'}>
                            <FontAwesomeIcon icon={dir == 1 ? faChevronLeft : faChevronRight} style={{ color: '#fff', marginRight: 7 }} />
                          </Col>
                        </Row>
                      </Link>
                      <div className='widget-box-body'>
                        {!this.state.examsList &&
                          <div style={{ paddingTop: 50, textAlign: 'center' }}>
                            <Spinner animation="grow" />
                          </div>
                        }
                        {this.state.examsList?.length == 0 &&
                          <div style={{ paddingTop: 50, textAlign: 'center' }}>
                            {t('No Data Found!')}
                          </div>
                        }
                        {this.state.examsList?.length > 0 &&
                          <div className='widget-box-list'>
                            {this.state.examsList.map((item, index) => {
                              return (
                                <div key={index} className={dir == 1 ? 'widget-box-list-item-ar' : 'widget-box-list-item'}>
                                  {item.title}
                                </div>
                              )
                            }
                            )}
                          </div>
                        }
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className='slider-wrap three-slider d-flex flex-wrap'>
                  {this.state.subjects !== null ? (
                    <>
                      {this.state.subjects.length > 0 ? (
                        <>
                          {this.renderSubjects()}
                        </>
                      ) : (
                        <div
                          style={{
                            height: '96px',
                          }}
                          className='d-flex justify-content-center align-items-center'>
                          {t('No subjects found!')}
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        height: '96px',
                        flexDirection: 'column',
                      }}
                      className='d-flex justify-content-center align-items-center'>
                      <span className='mb-2'>
                        <Spinner
                          animation='grow'
                          variant='dark'
                        />
                      </span>
                      {t('Fetching subjects...')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Offcanvas
          id='dashboard-ut'
          show={this.state.showUT} onHide={this.handleCloseUT} placement={(dir == 1) ? 'start' : 'end'}>
          <Offcanvas.Body className='p-0'>
            <Timeline
              utWorking={utWorking}
              utTypes={utTypes}
              selectedUtTypes={selectedUtTypes}
              startDate={startDate}
              endDate={endDate}
              handleUtDateRange={this.handleUtDateRange}
              handleSelectUtTypes={this.handleSelectUtTypes}
              getTimeline={this.getTimeline}
              resetFilter={this.resetFilter}
              applyFilter={this.applyFilter}
              userTimelineData={userTimelineData}
              loadMore={loadMore}
              handleCloseUT={this.handleCloseUT}
            />
          </Offcanvas.Body>
        </Offcanvas>
        <button
          onClick={this.handleOpenUT}
          title={t('Open News Feed')}
          className='ut-toggle'>
          <FontAwesomeIcon icon={(dir == 1) ? faAngleRight : faAngleLeft} />
        </button>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedChild: state.selectedChild.data,
  languages: state.language.languages,
  defaultLanguage: state.language.defaultLanguage,
})

const mapDispatchToProps = () => ({
  unsetAuthentication,
  setSelectedChild,
  setDefaultLanguage,
})

export default connect(mapStateToProps, mapDispatchToProps())(Dashboard)
