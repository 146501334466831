import React, { Component } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, } from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';

import { Card, Col, Image, Row, Form, Button, Spinner, Modal, Badge, ButtonGroup, Nav, NavItem, NavLink, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faShareAlt, faThumbsUp, faComment, faCalendarAlt, faStop, faImage, faPoll, faTasks, faListAlt, faVideo, faDesktop, faFileAlt, faClock, faBook, faTh, faTachometer, faQuestionCircle, faAward, faCalendar, faLink, faPaperclip, faChevronLeft, faChevronRight, faTimesCircle, faEye, faHeart, faEyeSlash, faArrowAltCircleRight, faArrowAltCircleLeft, faEllipsisV, faEdit, faTrash, faArrowUpRightFromSquare, faMoneyBill, faDotCircle, faNotesMedical, faChartArea, } from '@fortawesome/free-solid-svg-icons';
import ReactPlayer from 'react-player';
import moment from 'moment';
import ImageViewer from 'react-simple-image-viewer';
import Slider from 'react-slick';
import ReactStars from 'react-rating-stars-component';
import { toast } from 'react-toastify';
import axios from 'axios';
import { t } from '../../helpers/translation_helper';
import { CyanProcess, VividMalachite, CerisePink, White, Black, ChineseSilver, SunsetOrange, AliceBlue, OrangeRYB, generateColor, Gray, } from '../../helpers/brand_colors_helper';
import { faFileLines, faNewspaper, faNoteSticky } from '@fortawesome/free-regular-svg-icons';
import { Chance } from 'chance';
import { Link } from 'react-router-dom';
import { handleFormErrors } from '../../helpers/form_helpers';
import AcademicActivityReflection from '../Dashboard/AcademicActivityReflection';
const chance = new Chance();
const ContrastColor = require('contrast-color');



ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;
const appURL = process.env.REACT_APP_URL;



/**
 * Timeline Card Component
 */
class TimelineCard extends Component {

  constructor(props) {
    super();
    // State
    this.state = {
      currentImage: 0,
      isViewerOpen: false,
      images: [],
      liked: 0,
      likesModal: false,
      commentsModal: false,
      seenModal: false,
      comment: '',
      commentSending: false,
      attachment: null,
      comments: null,
      commentsFinished: false,
      utc_is_root: 1,
      utc_root_id: 0,
      editBtnModal: {
        show: false,
        toggleModal: () => this.setState({ editBtnModal: { ...this.state.editBtnModal, show: !this.state.editBtnModal.show } })
      },
      resultsModal: {
        show: false,
        toggleModal: (ut_id = null) => this.setState({ resultsModal: { ...this.state.resultsModal, show: (ut_id) ? true : false, activeTab: 0 } }, () => {
          if (ut_id) {
            this.getPollSurveyResults(ut_id)
          }
        }),
        data: null,
        activeTab: 0,
        toggleTab: (activeTab) => this.setState({ resultsModal: { ...this.state.resultsModal, activeTab } })
      },
      allowEdit: true,
      currentSurveyQuestion: 0,
      academicActivityReflectionModal: {
        show: false,
        modalTitle: 'Assignment',
        data: null,
        closeModal: () => this.setState({ academicActivityReflectionModal: { ...this.state.academicActivityReflectionModal, show: false } }),
        toggleModal: (modalTitle = null, data = null) => this.setState({
          academicActivityReflectionModal: { ...this.state.academicActivityReflectionModal, show: !this.state.academicActivityReflectionModal.show, modalTitle, data }
        }),
        showModal: () => this.setState({ academicActivityReflectionModal: { ...this.state.academicActivityReflectionModal, show: true } }),
      },
    };

    this.defaultTimelineTypes = {
      "Online Homework": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Online Homework",
        picture: require('../../assets/images/user-timeline/newsletter.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Agenda": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Agenda",
        picture: require('../../assets/images/user-timeline/agenda.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Assignment": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Assignment",
        picture: require('../../assets/images/user-timeline/assignment.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Exam": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Exam",
        picture: require('../../assets/images/user-timeline/exam.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Academic Activity": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Activity",
        picture: require('../../assets/images/user-timeline/activity.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
    }



    /**
     * Constructor
     */
    this.commentAttach = React.createRef();
  }

  componentDidMount() {
    this.setState({ liked: this.props.data.utt_liked });
  }

  /**
   * Handle Modals
   */
  handleLikesModal = () => {
    const { likesModal } = this.state;
    this.setState({
      likesModal: !likesModal,
    });
  };
  handleCommentsModal = () => {
    const { commentsModal } = this.state;
    this.setState({
      commentsModal: !commentsModal,
      utc_is_root: 1,
      utc_root_id: 0,
    });
  };
  handleSeenModal = () => {
    const { seenModal } = this.state;
    this.setState({
      seenModal: !seenModal,
    });
  };

  /**
   * Open Image Viewer
   * @param {number} index Index of the image
   * @param {number} center_id ID of the center
   * @param {string[]} images Array of images
   */
  openImageViewer = (index, center_id, images) => {
    const images_arr = images.map((item) => {
      return (
        portalURL + center_id + '/user_timeline_media/' + item.utm_name
      );
    });

    console.log(index, center_id, images_arr);

    this.setState({
      currentIndex: index,
      isViewerOpen: true,
      images: images_arr,
    });
  };

  openSingleImageViewer = (index, image) => {
    const images_arr = [image]
    console.log('images_arr', images_arr);
    this.setState({
      currentIndex: 0,
      isViewerOpen: true,
      images: images_arr,
    });
  };

  /**
   * Close Image Viewer
   */
  closeImageViewer = () => {
    this.setState({
      currentIndex: 0,
      isViewerOpen: false,
      images: [],
    });
  };



  renderDescription = (data) => {
    if (data.length > 100 && this.state.readMore != true) {
      return <>{data.slice(0, 100)} <Badge bg="dark" className='cursor-pointer' onClick={() => this.setState({ readMore: true })}>Read More...</Badge></>;
    } else {
      return <>{data} {data.length > 100 && <Badge bg="dark" className='cursor-pointer' onClick={() => this.setState({ readMore: false })}>Read Less...</Badge>}</>;
    }
  }

  fgColor = (color) => {
    const cc = new ContrastColor({
      bgColor: color,
      threshold: 200,
    });
    const defaultFgColor = cc.contrastColor();
    return defaultFgColor;
  }

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    // State
    const {
      isViewerOpen,
      currentImage,
      images,
      likesModal,
      commentsModal,
      seenModal,
      comment,
      commentSending,
      attachment,
      comments,
      utc_root_id,
      academicActivityReflectionModal
    } = this.state;

    // Props
    const { data, authData, dir, children, showClass, allowEdit } = this.props;

    //  Video
    let video_url;
    if (data.ut_type == 'Video') {
      if (data.user_timeline_media[0].utm_url !== null) {
        video_url = data.user_timeline_media[0].utm_url.replace(
          'videos',
          'video'
        );
      }
    }


    return (
      <Card className='mb-3 border-0 timeline-card class-view-ut-card'>
        <Card.Body>
          <Row className='ut-card-header'>
            <Col>
              <div className='card-type'>
                <div className='card-icon'>
                  <Image src={this.defaultTimelineTypes[data.ut_type].picture} style={{ width: '100%', height: '100%' }} />
                </div>
                {t(this.defaultTimelineTypes[data.ut_type].name)}
              </div>
            </Col>
            {data.start_datetime &&
              (
                <Col className='card-date'>
                  <p className='mb-1'>
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className='me-1'
                      color={VividMalachite}
                      title={t("Start Date/Time")}
                    />
                    {moment(
                      data.start_datetime,
                      'YYYY-MM-DD HH:mm:ss '
                    ).format('dddd, MMMM Do YYYY')}
                  </p>
                </Col>
              )
            }
            {data.end_datetime &&
              (
                <Col className='card-date'>
                  <p className='mb-1'>
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className='me-1'
                      color={SunsetOrange}
                      title={t("End Date/Time")}
                    />
                    {moment(
                      data.end_datetime,
                      'YYYY-MM-DD HH:mm:ss '
                    ).format('dddd, MMMM Do YYYY')}
                  </p>
                </Col>
              )
            }
            {data.exams && data.exams.exams_date &&
              (
                <>
                  <Col></Col>
                  <Col className='card-date'>
                    <p className='mb-1'>
                      <FontAwesomeIcon
                        icon={faCalendar}
                        className='me-1'
                      />{' '}
                      {moment(
                        data.exams.exams_date,
                        'YYYY-MM-DD'
                      ).format('dddd, MMMM Do YYYY')}
                    </p>
                  </Col>
                </>
              )
            }
            <div className='d-flex justify-content-end align-items-start' style={{ width: '171px' }}>
              {/* <a href='#' className='card-share'>
                                <FontAwesomeIcon icon={faShareAlt} /> Share
                            </a> */}
              <div>
                {(data.ut_publish_status == 1)
                  ? <div className='card-share' title={'Published @ ' + moment(data.updated_on).format('DD-MM-YYYY HH:mm:ss')}>
                    <span style={{ marginLeft: 5, marginRight: 5 }}><FontAwesomeIcon icon={faClock} color={VividMalachite} /></span>
                    {moment(data.updated_on).from(moment(data.current_datetime))}
                  </div>
                  : <div className='card-share' title={(data.ut_type == 'Assessment' || data.ut_type == 'Daily Report') ? 'Not Shared' : (data.ut_publish_on) ? 'Published @ ' + moment(data.ut_publish_on).format('DD-MM-YYYY HH:mm:ss') : 'Not Published'}>
                    <span style={{ marginLeft: 5, marginRight: 5 }}><FontAwesomeIcon icon={faClock} color={SunsetOrange} /></span>
                    {(data.ut_type == 'Assessment' || data.ut_type == 'Daily Report') ? 'Not Shared' : (data.ut_publish_on) ? moment(data.ut_publish_on).from(moment(data.current_datetime)) : 'Not Published'}
                  </div>
                }
              </div>

            </div>
          </Row>


          {isViewerOpen && (
            <ImageViewer
              src={images}
              currentIndex={currentImage}
              disableScroll={true}
              closeOnClickOutside={true}
              onClose={this.closeImageViewer}
            />
          )}

          <hr style={{ marginTop: 5, marginBottom: 5 }} />

          <Row>
            <Col className='card-title'>{data.ut_title}</Col>
            <Col className='card-action text-end'>
              <ButtonGroup size="sm">
                {(data.ut_type == 'Exam') &&
                  <Link to={'/attempt-exam/' + data.exams.exams_id + '/0/false'}
                    style={{ fontSize: 12 }} className='btn btn-sm btn-primary'>
                    {t('Attempt Exam')}
                  </Link>
                }
                {(data.ut_type == 'Homework') &&
                  <Link
                    to={'/attempt-exam/' + data.exams.exams_id + '/0/true'}
                    style={{ fontSize: 12 }}
                    className='btn btn-sm btn-primary'>
                    {t('Attempt Homework')}
                  </Link>
                }
                {data.ut_type == 'Academic Activity' &&
                  <Button variant='success' title={t('View')} onClick={() => academicActivityReflectionModal.toggleModal(data.ut_type, data)}>
                    {t('Open')}
                  </Button>
                }
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col className='card-desc'>

              {data.ut_description && <div className='mb-1'>{this.renderDescription(data.ut_description)}</div>}

              <div className='card-desc-para'>

                {data.ut_url !== '' && data.ut_type == 'Event' && (
                  <p className='mb-1'>
                    <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare}
                      className='me-1'
                      color={VividMalachite}
                    />
                    <a
                      rel='noreferrer'
                      href={data.ut_url}
                      target='_blank'>
                      {data.ut_url}
                    </a>
                  </p>
                )}
                {/* WILL BE REMOVED */}
                {data.classmaterial &&
                  <p className='mb-1'>
                    <FontAwesomeIcon
                      icon={faBook}
                      className='me-1'
                      color={VividMalachite}
                    />{' '}
                    {data.classmaterial.classmaterial_name}
                  </p>
                }

              </div>

              <div className='class-view-attachment'>
                {data.ut_attachment_1 !== null && (data.ut_attachment_1.split('.').pop()).toLowerCase() === 'pdf' && (
                  <p className='mb-1'>
                    <FontAwesomeIcon
                      icon={faLink}
                      className='me-1'
                      color={VividMalachite}
                    />
                    <a
                      rel='noreferrer'
                      href={(data.ut_folder) ? portalURL + data.center_id + '/' + data.ut_folder + '/' + data.ut_attachment_1 : portalURL + data.center_id + '/user_timeline/' + data.ut_attachment_1}
                      target='_blank'>
                      {t('Open PDF')}
                    </a>
                  </p>
                )}
                {data.ut_attachment_2 !== null && (data.ut_attachment_2.split('.').pop()).toLowerCase() === 'pdf' && (
                  <p className='mb-1'>
                    <FontAwesomeIcon
                      icon={faLink}
                      className='me-1'
                      color={VividMalachite}
                    />
                    <a
                      rel='noreferrer'
                      href={(data.ut_folder) ? portalURL + data.center_id + '/' + data.ut_folder + '/' + data.ut_attachment_2 : portalURL + data.center_id + '/user_timeline/' + data.ut_attachment_2}
                      target='_blank'>
                      {t('Open PDF')}
                    </a>
                  </p>
                )}
              </div>

              {/* ACTIVITY */}
              {(data.ut_type == 'Academic Activity') && <>
                <p className='mb-1'>
                  <strong>{t('Activity')}:</strong>{' '}
                  {data.academic_activities.aa_name}
                </p>
              </>
              }

              {/* AGENDA */}
              {data.ut_type == 'Agenda' && data.academic_classmain_subject &&
                <div className='academic-subjects'>
                  <div style={{ marginRight: 10, lineHeight: '25px' }}><strong>{t('SUBJECTS:')}</strong></div>
                  {data.academic_classmain_subject.map((subject, subject_i) => <div key={subject_i} className='subject-tag' style={{ backgroundColor: subject.as_color, color: this.fgColor(subject.as_color) }}>
                    <img src={portalURL + subject.center_id + '/academic_classmain_subject/' + subject.acs_image} width="10" height="10" style={{ marginRight: 5, position: 'relative', top: -2 }} alt={t('Subject Image')} />
                    {subject.as_name.toUpperCase()}
                  </div>)}
                </div>
              }

            </Col>
          </Row>
          {data.ut_type == 'Exam' && (
            <Row>
              <Col className='exam-details'>
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faTh}
                    className='me-1'
                  />{' '}
                  {data.exams.examstype_name} (
                  {data.exams.examstypegrp_name})
                </p>
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faBook}
                    className='me-1'
                    color={VividMalachite}
                  />{' '}
                  {data.exams.classmaterial_name}
                </p>
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faTachometer}
                    className='me-1'
                  />{' '}
                  <span
                    style={{
                      color: data.exams
                        .eqdifficulty_color,
                    }}>
                    {data.exams.eqdifficulty_name}
                  </span>
                </p>
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className='me-1'
                  />{' '}
                  {data.exams.total_questions}
                </p>
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faAward}
                    className='me-1'
                  />{' '}
                  {data.exams.exams_grade} ({t('Total Grade')}
                  )
                </p>
              </Col>
            </Row>
          )}
        </Card.Body>
        <AcademicActivityReflection academicActivityReflectionModal={this.state.academicActivityReflectionModal} />
      </Card>
    );
  }
}

export default TimelineCard;

