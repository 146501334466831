import React, { Component } from 'react';
import { Button, Col, Row, ListGroup, Modal, Form } from 'react-bootstrap';
import ChildSelector from './ChildSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faBell, faSearch, faLanguage, faCheckCircle, faArrowAltCircleLeft, faArrowAltCircleRight, faCommentAlt, faRefresh, faEye, } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { unsetAuthentication } from '../../../redux/slices/authSlice';
import { setSelectedChild } from '../../../redux/slices/selectedChildSlice';
import { setDefaultLanguage } from '../../../redux/slices/langSlice';
import { setToggleChat } from '../../../redux/slices/chatsSlice';
import { t } from '../../../helpers/translation_helper';
import './header.css';
import { Black, CerisePink, SunsetOrange, VividMalachite, White } from '../../../helpers/brand_colors_helper';
import { refreshLangLabels, setLanguages } from '../../../redux/slices/langSlice';
import axios from 'axios';
import { toast } from 'react-toastify';
import ImageViewer from 'react-simple-image-viewer';

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;
const apiURL = process.env.REACT_APP_API_URL;

/**
 * Header Partial Component
 */
class Header extends Component {
    // State
    state = {
        searchModal: false,
        notificationModal: false,
        languageModal: false,
        openCoverImage: false
    };

    /**
     * Handle Modal
     * @param {string} modal - Modal name
     */
    handleModal = (modal) => {
        this.setState({
            [modal]: !this.state[modal],
        });
    };

    /**
     * Languages List
     * @returns {HTMLElement} Outputs the languages list in UL LI
     */
    languagesList = () => {
        const { languages, defaultLanguage } = this.props;

        const langList = languages.map((item, index) => {
            const flag = 'me-1 fi fi-' + item.lang_flag.toLowerCase();
            return (
                <ListGroup.Item
                    key={index}
                    action
                    className='px-2'
                    onClick={() => {
                        this.props.setDefaultLanguage(item);
                        this.handleModal('languageModal');
                    }}>
                    <Row>
                        <Col>
                            <span className={flag}></span> {item.lang_name}
                        </Col>
                        <Col
                            className='d-flex align-items-center justify-content-end'
                            style={{ fontSize: '20px' }}>
                            {defaultLanguage.lang_id == item.lang_id && (
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    color='#00D045'
                                />
                            )}
                        </Col>
                    </Row>
                </ListGroup.Item>
            );
        });

        return langList;
    };

    refreshLanguagesData = () => {
        this.props.refreshLangLabels(this.state.langOffset)
        axios.get(apiURL + 'language/get-languages').then((res) => {
            if (res.data.type) {
                this.props.setLanguages(res.data.data);
                toast.success(t('Languages Data has been updated'));
            }
        });
    }

    /**
     * Render Component
     * @returns {HTMLelement}
     */
    render() {
        // Language Direction
        const { lang_orientation: dir } = this.props.defaultLanguage;

        // State
        const { searchModal, notificationModal, languageModal, openCoverImage } = this.state;

        // Props
        const { lite, heading, authData } = this.props;

        // Header BG Image
        let headerBG = '';
        if (lite) {
            headerBG = null;
        } else {
            if (authData.stdbanners) {
                headerBG = portalURL + authData.stdbanners.center_id + '/stdbanners/' + authData.stdbanners.picture
            }
            else if (this.props.selectedChild) {
                headerBG = portalURL + this.props.selectedChild.center_id + '/children/' + this.props.selectedChild.cover_picture
            }
        }



        return (
            <>
                <header id='iedu-header' className={lite ? 'pt-3 lite-header' : 'pt-3'} style={{ backgroundImage: 'url(' + headerBG + ')' }}>
                    <Row>
                        <Col className='d-flex align-items-center'>
                            {heading ? (
                                <>
                                    {this.props.backBtn && (
                                        <span className='header-back-btn' onClick={() => window.history.back()}>
                                            <FontAwesomeIcon color={Black} icon={(dir == 1) ? faArrowAltCircleRight : faArrowAltCircleLeft} />
                                        </span>
                                    )}
                                    <h4 className={(dir == 1) ? 'mb-0 pe-2' : 'mb-0 ps-2'}>{heading}</h4>
                                </>
                            ) : (
                                <ChildSelector func={this.props.func} />
                            )}
                        </Col>
                        <Col className='d-flex flex-column justify-content-between' md={'auto'}>
                            <div className='d-flex flex-row justify-content-end'>
                                {this.props.showChildSelector && <ChildSelector func={this.props.func} minimal={true} />}
                                <Button style={{ position: 'relative' }} onClick={() => this.props.setToggleChat()} className='btn-header ms-2' variant='success'>
                                    <FontAwesomeIcon icon={faCommentAlt} color='#ffffff' />
                                    <div style={{ position: 'absolute', top: -5, right: -5 }}>
                                        {this.props.chatsCount?.length > 0 && (
                                            <div style={{ backgroundColor: SunsetOrange, color: White, borderRadius: 100, width: 20, height: 20, fontWeight: 'bold', fontSize: 11, padding: 2 }}>{this.props.chatsCount.length}</div>
                                        )}
                                    </div>
                                </Button>
                                <Button onClick={() => this.handleModal('searchModal')} className='btn-header ms-2' variant='secondary'>
                                    <FontAwesomeIcon icon={faSearch} color='#ffffff' />
                                </Button>
                                <Button onClick={() => this.handleModal('notificationModal')} className='btn-header ms-2' variant='secondary'>
                                    <FontAwesomeIcon icon={faBell} color='#ffffff' />
                                </Button>
                                <Button onClick={() => this.handleModal('languageModal')} className='btn-header ms-2' variant='secondary'>
                                    <FontAwesomeIcon icon={faLanguage} color='#ffffff' />
                                </Button>
                                <Link to='/' onClick={() => {
                                    this.props.unsetAuthentication();
                                    this.props.setSelectedChild(undefined);
                                }} className='btn-header ms-2'>
                                    <FontAwesomeIcon icon={faPowerOff} color='#ffffff' />
                                </Link>
                            </div>
                            {headerBG &&
                                <div className='d-flex flex-row justify-content-end'>
                                    <div title={t('Show cover image')} className='badge badge-light-primary cursor-pointer' onClick={() => this.setState({ openCoverImage: headerBG })}>
                                        <FontAwesomeIcon icon={faEye} style={{ fontSize: 20 }} />
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                </header>
                <Modal
                    show={searchModal}
                    onHide={() => this.handleModal('searchModal')}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Search')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control
                            type='text'
                            placeholder={t('search here...')}
                        />
                    </Modal.Body>
                </Modal>
                <Modal
                    show={notificationModal}
                    onHide={() => this.handleModal('notificationModal')}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Notifications')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{t('No new notifications')}</Modal.Body>
                </Modal>
                <Modal
                    size='sm'
                    show={languageModal}
                    onHide={() => this.handleModal('languageModal')}>
                    <Modal.Header>
                        <div className='d-flex flex-row align-items-center justify-content-between' style={{ width: '100%' }}>
                            <Modal.Title>{t('Select Language')}</Modal.Title>
                            <FontAwesomeIcon className='cursor-pointer' style={{ fontSize: 22 }} icon={faRefresh} color={CerisePink} title={t('Refresh Languages Data')} onClick={() => this.refreshLanguagesData()} />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <ListGroup variant='flush'>
                            {this.languagesList()}
                        </ListGroup>
                    </Modal.Body>
                </Modal>

                {openCoverImage && (
                    <ImageViewer
                        backgroundStyle={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
                        src={[openCoverImage]}
                        currentIndex={0}
                        disableScroll={true}
                        closeOnClickOutside={true}
                        onClose={() => this.setState({ openCoverImage: false })}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedChild: state.selectedChild.data,
    languages: state.language.languages,
    defaultLanguage: state.language.defaultLanguage,
    chatsCount: state.chats.chatsCount,
});

const mapDispatchToProps = () => ({
    unsetAuthentication,
    setSelectedChild,
    setDefaultLanguage,
    setToggleChat,
    refreshLangLabels,
    setLanguages
});

export default connect(mapStateToProps, mapDispatchToProps())(Header);
