import React, { Component } from 'react';
import {
    Container,
    Card,
    Spinner,
    Row,
    Col,
    Button,
    Badge,
    Form,
} from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import axios from 'axios';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, Link, Navigate } from 'react-router-dom';
import {
    faArrowLeft,
    faCalendarAlt,
    faCancel,
    faClock,
    faClose,
    faExternalLink,
    faFileAlt,
    faLink,
    faSave,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment/moment';
import { withParams } from '../../helpers/params_helper';
import './assignments.css';
import { Recorder } from 'react-voice-recorder';
import 'react-voice-recorder/dist/index.css';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

/**
 * Assignment Component
 */
class Assignment extends Component {
    // State
    state = {
        working: false,
        shouldRedirect: false,
        assignment: null,
        audioDetails: {
            url: null,
            blob: null,
            chunks: null,
            duration: {
                h: 0,
                m: 0,
                s: 0,
            },
        },
        attachment: null,
        attachment2: null,
        comment: null,
    };

    /**
     * Component Did Mount
     */
    componentDidMount() {
        this.getAssignments();
    }

    /**
     * Get Assignments
     */
    getAssignments = async () => {
        const { authData, params } = this.props;
        const { auth_key } = authData.loginData;
        const { center_id } = authData.centerData;
        const { selectedChild } = this.props;
        let request_params = {
            center_id,
            child_id: selectedChild.child_id,
            stdassign_detail_id: params.assid,
            auth_key: auth_key,
        };

        try {
            const res = await axios.get(
                apiURL + 'stdassign/edit-stdassign-details',
                {
                    params: request_params,
                }
            );

            console.log('res', res);

            this.setState({
                assignment: res.data,
                comment: res.data.comment,
                attachment: res.data.attachment,
                attachment2: res.data.attachment2,
                // audioDetails: res.data.audio_record,
            });
        } catch (err) {
            toast.error('Something went wrong while fetching project details!');
            console.log(err);
        }
    };

    /**
     * Handle Audio Stop
     * @param {Object} data
     */
    handleAudioStop = (data) => {
        this.setState({ audioDetails: data });
    };

    /**
     * Handle Reset Audio
     */
    handleReset = () => {
        const reset = {
            url: null,
            blob: null,
            chunks: null,
            duration: {
                h: null,
                m: null,
                s: null,
            },
        };
        this.setState({ audioDetails: reset });
    };

    /**
     * Handle Comment
     */
    handleComment = (e) => {
        this.setState({
            comment: e.target.value,
        });
    };

    /**
     * Set File
     * @param {string} name State name
     * @returns
     */
    setFile = (name) => (e) => {
        this.setState({
            [name]: e.target.files[0],
        });
    };

    handleSaveSubmit = async () => {
        this.setState({ working: true });
        const { authData, params } = this.props;
        const { auth_key } = authData.loginData;
        const { center_id, center_timezone } = authData.centerData;
        const { selectedChild } = this.props;
        const { assignment, attachment, attachment2, comment, audioDetails } =
            this.state;

        const stdassign_details = JSON.stringify({
            stdassign_detail_id: assignment.stdassign_detail_id,
            stdassign_id: assignment.stdassign_id,
            child_id: assignment.child_id,
            parent_id: assignment.parent_id,
            comment: comment,
            attachment: assignment.attachment,
            attachment2: assignment.attachment2,
            audio_record: assignment.audio_record,
            is_read_by_student: assignment.is_read_by_student,
            submitted_by_student: assignment.submitted_by_student,
            is_read_by_teacher: assignment.is_read_by_teacher,
            notified_by_teacher: assignment.notified_by_teacher,
            notified_count: assignment.notified_count,
            center_id: assignment.center_id,
            teacher_comment: assignment.teacher_comment,
            teacher_attachment: assignment.teacher_attachment,
        });

        const fData = new FormData();
        fData.append('center_id', center_id);
        fData.append('child_id', selectedChild.child_id);
        fData.append('parent_id', selectedChild.parent_id);
        fData.append('center_timezone', center_timezone);
        fData.append('stdassign_id', assignment.stdassign_id);
        fData.append('stdassign_detail_id', assignment.stdassign_detail_id);
        fData.append('ut_id', assignment.ut_id);
        fData.append('attachment', attachment);
        fData.append('attachment2', attachment2);
        fData.append('audio_record', audioDetails.blob);
        fData.append('stdassign_details', stdassign_details);

        try {
            const req = await axios.post(
                apiURL + 'stdassign/save-stdassign-details',
                fData,
                {
                    params: {
                        auth_key: auth_key,
                    },
                }
            );

            console.log('req', req);

            if (req.status === 200) {
                if (req.data.type) {
                    toast.success(t('Project saved successfully!'));
                    this.setState({
                        shouldRedirect: true,
                        working: false,
                    });
                } else {
                    toast.error(req.data.messageTitle + ' ' + req.data.message);
                    this.setState({
                        working: false,
                    });
                }
            }
        } catch (err) {
            toast.error('Something went wrong while saving project!');
            this.setState({ working: false });
            console.log(err);
        }
    };

    /**
     * Render Component
     * @returns {HTMLElement}
     */
    render() {
        // Language Direction
        const { lang_orientation: dir } = this.props.defaultLanguage;

        // State
        const {
            working,
            assignment,
            comment,
            attachment,
            attachment2,
            shouldRedirect,
        } = this.state;

        // Props
        const { params } = this.props;

        if (shouldRedirect) {
            return <Navigate replace to='/projects' />;
        }

        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Project:') + ' ' + params.assname} backBtn={true} />
                        {assignment !== null ? (
                            <div id='assignment' className='grey-section'>
                                <Card
                                    className='border-0'
                                    style={{ borderRadius: 20 }}>
                                    <Card.Body>
                                        <Row>
                                            <Col md={4}>
                                                <Card.Text>
                                                    {assignment.stdassign_text1}
                                                </Card.Text>
                                                <Badge>
                                                    <FontAwesomeIcon
                                                        icon={faCalendarAlt}
                                                    />{' '}
                                                    {t('Posted:')}{' '}
                                                    {moment(
                                                        assignment.stdassign_date
                                                    ).format('DD-MM-YYYY')}
                                                </Badge>{' '}
                                                <Badge
                                                    bg='warning'
                                                    className='text-dark mb-3'>
                                                    <FontAwesomeIcon
                                                        icon={faCalendarAlt}
                                                    />{' '}
                                                    {t('Due:')}{' '}
                                                    {moment(
                                                        assignment.stdassign_duedate
                                                    ).format('DD-MM-YYYY')}
                                                </Badge>
                                            </Col>
                                            <Col>
                                                {assignment.stdassign_text2 && (
                                                    <Card.Text>
                                                        <strong>
                                                            {t('Note 1:')}
                                                        </strong>
                                                        <br />
                                                        {
                                                            assignment.stdassign_text2
                                                        }
                                                    </Card.Text>
                                                )}
                                                {assignment.stdassign_text3 && (
                                                    <Card.Text>
                                                        <strong>
                                                            {t('Note 2:')}
                                                        </strong>
                                                        <br />
                                                        {
                                                            assignment.stdassign_text3
                                                        }
                                                    </Card.Text>
                                                )}
                                                {assignment.stdassign_link && (
                                                    <Card.Text>
                                                        <FontAwesomeIcon
                                                            icon={faLink}
                                                        />{' '}
                                                        <a
                                                            rel='noreferrer'
                                                            href={
                                                                assignment.stdassign_link
                                                            }
                                                            target='_blank'>
                                                            {
                                                                assignment.stdassign_link
                                                            }
                                                        </a>
                                                    </Card.Text>
                                                )}
                                            </Col>
                                            <Col
                                                md={2}
                                                className='d-flex justify-content-end'>
                                                {assignment.stdassign_picture ? (
                                                    <img
                                                        alt=''
                                                        className='img-fluid'
                                                        src={
                                                            portalURL +
                                                            assignment.center_id +
                                                            '/stdassign/' +
                                                            assignment.stdassign_picture
                                                        }
                                                    />
                                                ) : (
                                                    <img
                                                        alt=''
                                                        className='img-fluid'
                                                        src='https://ieducore.com/educore2/web/images/assignment.jpg'
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className='mb-3'>
                                            <Col>
                                                <Recorder
                                                    record={true}
                                                    audioURL={
                                                        this.state.audioDetails
                                                            .url
                                                    }
                                                    showUIAudio
                                                    handleAudioStop={(data) =>
                                                        this.handleAudioStop(
                                                            data
                                                        )
                                                    }
                                                    handleOnChange={(value) =>
                                                        this.handleOnChange(
                                                            value,
                                                            'firstname'
                                                        )
                                                    }
                                                    handleReset={() =>
                                                        this.handleReset()
                                                    }
                                                    hideHeader={true}
                                                    uploadButtonDisabled={true}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    as='textarea'
                                                    rows={16}
                                                    placeholder={t('Comment')}
                                                    className='mb-3'
                                                    onChange={
                                                        this.handleComment
                                                    }
                                                    value={comment}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group
                                                    controlId='formFile'
                                                    className='mb-3'>
                                                    <Form.Label>
                                                        {t('Attachment 1')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type='file'
                                                        onChange={this.setFile(
                                                            'attachment'
                                                        )}
                                                    />
                                                </Form.Group>
                                                {attachment &&
                                                    typeof attachment ==
                                                    'string' && (
                                                        <div className='pic-prev d-flex justify-content-center'>
                                                            <img
                                                                alt=''
                                                                style={{
                                                                    maxWidth: 270,
                                                                    height: '100%',
                                                                }}
                                                                src={
                                                                    portalURL +
                                                                    assignment.center_id +
                                                                    '/stdassign_details/' +
                                                                    attachment
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                            </Col>
                                            <Col>
                                                <Form.Group
                                                    controlId='formFile'
                                                    className='mb-3'>
                                                    <Form.Label>
                                                        {t('Attachment 2')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type='file'
                                                        onChange={this.setFile(
                                                            'attachment2'
                                                        )}
                                                    />
                                                </Form.Group>
                                                {attachment2 &&
                                                    typeof attachment2 ==
                                                    'string' && (
                                                        <div className='pic-prev d-flex justify-content-center'>
                                                            <img
                                                                alt=''
                                                                style={{
                                                                    maxWidth: 270,
                                                                    height: '100%',
                                                                }}
                                                                src={
                                                                    portalURL +
                                                                    assignment.center_id +
                                                                    '/stdassign_details/' +
                                                                    attachment2
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                            </Col>
                                        </Row>
                                        <Row style={{ paddingTop: 20 }}>
                                            <Col className='d-flex justify-content-end'>
                                                <Link
                                                    className='me-2 btn btn-danger'
                                                    to='/projects'>
                                                    <FontAwesomeIcon
                                                        icon={faClose}
                                                        color='#ffffff'
                                                    />{' '}
                                                    {t('Cancel')}
                                                </Link>
                                                <Button
                                                    onClick={() =>
                                                        this.handleSaveSubmit()
                                                    }
                                                    disabled={working}
                                                    className='btn btn-success'>
                                                    <FontAwesomeIcon
                                                        icon={faSave}
                                                        color='#ffffff'
                                                    />{' '}
                                                    {t('Save & Submit')}
                                                    {working ? (
                                                        <Spinner
                                                            as='span'
                                                            animation='grow'
                                                            size='sm'
                                                            role='status'
                                                            aria-hidden='true'
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </div>
                        ) : (
                            <div
                                style={{
                                    height: '120px',
                                    flexDirection: 'column',
                                }}
                                className='d-flex justify-content-center align-items-center'>
                                {assignment == null ? (
                                    <>
                                        <span className='mb-1'>
                                            <Spinner
                                                animation='grow'
                                                variant='dark'
                                            />
                                        </span>
                                        {t('Fetching project details...')}
                                    </>
                                ) : (
                                    t('No project details are available!')
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedChild: state.selectedChild.data,
    languages: state.language.languages,
    defaultLanguage: state.language.defaultLanguage,
});
export default connect(mapStateToProps, null)(withParams(Assignment));
