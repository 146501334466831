import React, { Component } from 'react';
import { Container, Card, Spinner, Row, Col, Button } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import Paginate from '../_partials/Paginate/_Paginate';
import axios from 'axios';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, Link } from 'react-router-dom';
import { faCalendarAlt, faClock, faExternalLink, faFileAlt, faPlay } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment/moment';
import { withParams } from '../../helpers/params_helper';
import GeneralCard from '../../helpers/GeneralCard';
import { GET } from '../../api/index';
import { Black, VividMalachite } from '../../helpers/brand_colors_helper';

const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;

/**
 * Exams Component
 */
class Exams extends Component {
  // State
  state = {
    exams: [],
    isRefreshing: false,
    limit: 12,
    totalRecords: 0,
    search: '',
    order: 'exams.exams_date',
    dir: 'DESC',
  };

  /**
   * Component Did Mount
   */
  componentDidMount() {
    this.getExams();
  }

  /**
   * Get Exams
   */
  getExams = async (limit = this.state.limit, offset = 0) => {
    this.setState({ isRefreshing: true })
    const { authData, selectedChild } = this.props;

    const request_params = {
      child_id: selectedChild.child_id,
      is_homework: (this.props.is_homework) ? 1 : 0,
      limit: limit,
      offset: offset,
      // acs_id: (this.props.selectedSubject) ? this.props.selectedSubject.acs_id : null,
      class_id: selectedChild ? selectedChild.class_id : null,
      search: this.state.search,
      order: this.state.order,
      dir: this.state.dir
    };

    try {
      const res = await GET('exams/fetch-exams-table', {
        params: request_params,
      });

      this.setState({
        exams: res.data.data,
        totalRecords: parseInt(res.data.total_count_filtered),
        isRefreshing: false
      });
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching exams!'));

      this.setState({ isRefreshing: false })
    }
  };

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    // Language Direction
    const { lang_orientation: dir } = this.props.defaultLanguage;

    // State
    const { exams, isRefreshing, limit, totalRecords, page, search } = this.state;
    const { is_homework } = this.props

    console.log('is_homework', is_homework);
    // Heading
    let heading = (is_homework) ? t('Homeworks') : t('Exams');
    if (this.props.params.acs_name) {
      heading = (is_homework) ? t('Homeworks') : t('Exams') + ' ' + t(this.props.params.acs_name);
    }

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={heading} backBtn={true} showChildSelector={true} func={[this.getExams]} />
            {exams.length > 0 ? (
              <div className='d-flex flex-row flex-warp'>
                {exams.map((v, index) => {
                  console.log('v', v);
                  let data = {
                    allData: v,
                    name: v.exams_name,
                    code: (v.is_exampaper == 1) ? t('Paper') : t('Online'),
                    text1: v.examstype_name,
                    text2: (<><br /><FontAwesomeIcon icon={faClock} />{' '}{moment(v.exams_date + ' ' + v.open_time).format('DD-MM-YYYY HH:mm')} - {(is_homework) ? moment(v.due_date + ' ' + v.close_time).format('DD-MM-YYYY HH:mm') : v.close_time}</>),
                    description: v.exams_notes,
                    cardImage: (v.acs_image) ? portalURL + v.center_id + '/academic_classmain_subject/' + v.acs_image : require('../../assets/images/noimage.png'),
                    linkTo: '/attempt-exam/' + v.exams_id + '/' + (v.exam_retake_id ? v.exam_retake_id : 0) + '/' + is_homework,
                    extraTexts: [
                      { type: 'text', name: 'Subject', value: v.acs_name, icon: null, image: require('../../assets/images/asterisk.png'), iconColor: VividMalachite, onClick: () => { } },
                      { type: 'text', name: (v.exam_attempt_id) ? 'Attempted @ ' + v.start_datetime + ' -- ' + v.end_datetime : t('Start Attempt'), value: (v.exam_attempt_id) ? (v.end_datetime || v.start_datetime) : t('Start Attempt'), icon: faPlay, image: null, iconColor: (v.exam_attempt_id) ? VividMalachite : Black, onClick: () => { } },
                    ]
                  }
                  return <GeneralCard data={data} key={index} />;
                }
                )}
              </div>
            ) : (
              <div style={{ height: '120px', flexDirection: 'column', }} className='d-flex justify-content-center align-items-center'>
                <>
                  {isRefreshing && <span className='mb-1'>
                    <Spinner animation='grow' variant='dark' />
                  </span>
                  }
                  {!is_homework && (isRefreshing ? t('Fetching exams...') : t('No Exam Found'))}
                  {is_homework && (isRefreshing ? t('Fetching homeworks...') : t('No Homeworks Found'))}
                </>
              </div>
            )}
            <Paginate
              limit={this.state.limit}
              totalRecords={this.state.totalRecords}
              pageChange={this.getExams}
              defaultTheme={this.props.defaultTheme}
            />
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedChild: state.selectedChild.data,
  languages: state.language.languages,
  defaultLanguage: state.language.defaultLanguage,
});
export default connect(mapStateToProps, null)(withParams(Exams));
